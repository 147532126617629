@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Thin.ttf');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ThinItalic.ttf');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ExtraLight.ttf');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ExtraLightItalic.ttf');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Light.ttf');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-LightItalic.ttf');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Regular.ttf');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Italic.ttf');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Medium.ttf');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-MediumItalic.ttf');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-SemiBold.ttf');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-SemiBoldItalic.ttf');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Bold.ttf');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-BoldItalic.ttf');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ExtraBold.ttf');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ExtraBoldItalic.ttf');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Black.ttf');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-BlackItalic.ttf');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'stroke';
	src:url('stroke.eot?-yg5sl9');
	src:url('stroke.eot?#iefix-yg5sl9') format('embedded-opentype'),
		url('stroke.woff?-yg5sl9') format('woff'),
		url('stroke.ttf?-yg5sl9') format('truetype'),
		url('stroke.svg?-yg5sl9#stroke') format('svg');
	font-weight: normal;
	font-style: normal;
}

i {
	font-family: 'stroke';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.stroke-alignleft:before {
	content: "\e600";
}
.stroke-alignmiddle:before {
	content: "\e601";
}
.stroke-alignright:before {
	content: "\e602";
}
.stroke-arrow:before {
	content: "\e603";
}
.stroke-battery:before {
	content: "\e604";
}
.stroke-bell:before {
	content: "\e605";
}
.stroke-blank:before {
	content: "\e606";
}
.stroke-bluetooth:before {
	content: "\e607";
}
.stroke-browser:before {
	content: "\e608";
}
.stroke-camera:before {
	content: "\e609";
}
.stroke-cart:before {
	content: "\e60a";
}
.stroke-chat:before {
	content: "\e60b";
}
.stroke-checkmark:before {
	content: "\e60c";
}
.stroke-clock:before {
	content: "\e60d";
}
.stroke-close:before {
	content: "\e60e";
}
.stroke-cocktail:before {
	content: "\e60f";
}
.stroke-compass:before {
	content: "\e610";
}
.stroke-connection:before {
	content: "\e611";
}
.stroke-cursor:before {
	content: "\e612";
}
.stroke-display:before {
	content: "\e613";
}
.stroke-download:before {
	content: "\e614";
}
.stroke-drop:before {
	content: "\e615";
}
.stroke-emptyblank:before {
	content: "\e616";
}
.stroke-flag:before {
	content: "\e617";
}
.stroke-forward2:before {
	content: "\e618";
}
.stroke-forward:before {
	content: "\e619";
}
.stroke-gift:before {
	content: "\e61a";
}
.stroke-graphic:before {
	content: "\e61b";
}
.stroke-grid:before {
	content: "\e61c";
}
.stroke-hamburgermenu:before {
	content: "\e61d";
}
.stroke-headphones:before {
	content: "\e61e";
}
.stroke-heart:before {
	content: "\e61f";
}
.stroke-home:before {
	content: "\e620";
}
.stroke-hyperlink:before {
	content: "\e621";
}
.stroke-justifyall:before {
	content: "\e622";
}
.stroke-key:before {
	content: "\e623";
}
.stroke-label:before {
	content: "\e624";
}
.stroke-launch:before {
	content: "\e625";
}
.stroke-lockclosed:before {
	content: "\e626";
}
.stroke-lockopen:before {
	content: "\e627";
}
.stroke-magnet:before {
	content: "\e628";
}
.stroke-magnifyingglass2:before {
	content: "\e629";
}
.stroke-magnifyingglassminus:before {
	content: "\e62a";
}
.stroke-magnifyingglassplus:before {
	content: "\e62b";
}
.stroke-magnifyingglass:before {
	content: "\e62c";
}
.stroke-mail:before {
	content: "\e62d";
}
.stroke-mappin:before {
	content: "\e62e";
}
.stroke-map:before {
	content: "\e62f";
}
.stroke-maximize:before {
	content: "\e630";
}
.stroke-microphone:before {
	content: "\e631";
}
.stroke-minimize:before {
	content: "\e632";
}
.stroke-minus:before {
	content: "\e633";
}
.stroke-mouse:before {
	content: "\e634";
}
.stroke-music:before {
	content: "\e635";
}
.stroke-navigation:before {
	content: "\e636";
}
.stroke-notifications:before {
	content: "\e637";
}
.stroke-pause:before {
	content: "\e638";
}
.stroke-pencil:before {
	content: "\e639";
}
.stroke-play:before {
	content: "\e63a";
}
.stroke-plus:before {
	content: "\e63b";
}
.stroke-power:before {
	content: "\e63c";
}
.stroke-print:before {
	content: "\e63d";
}
.stroke-repeat:before {
	content: "\e63e";
}
.stroke-rule:before {
	content: "\e63f";
}
.stroke-selection:before {
	content: "\e640";
}
.stroke-settings:before {
	content: "\e641";
}
.stroke-share:before {
	content: "\e642";
}
.stroke-shuffle:before {
	content: "\e643";
}
.stroke-soundminus:before {
	content: "\e644";
}
.stroke-soundplus:before {
	content: "\e645";
}
.stroke-speaker:before {
	content: "\e646";
}
.stroke-star:before {
	content: "\e647";
}
.stroke-statistics:before {
	content: "\e648";
}
.stroke-stop:before {
	content: "\e649";
}
.stroke-sun:before {
	content: "\e64a";
}
.stroke-trashbin:before {
	content: "\e64b";
}
.stroke-umbrella:before {
	content: "\e64c";
}
.stroke-upload:before {
	content: "\e64d";
}
.stroke-videocamera:before {
	content: "\e64e";
}
.stroke-wifi:before {
	content: "\e64f";
}

.bigger-110 {
  font-size: 110% !important;
}
.bigger-120 {
  font-size: 120% !important;
}
.bigger-130 {
  font-size: 130% !important;
}
.bigger-140 {
  font-size: 140% !important;
}
.bigger-150 {
  font-size: 150% !important;
}
.bigger-160 {
  font-size: 160% !important;
}
.bigger-170 {
  font-size: 170% !important;
}
.bigger-180 {
  font-size: 180% !important;
}
.bigger-190 {
  font-size: 190% !important;
}
.bigger-200 {
  font-size: 200% !important;
}
.bigger-210 {
  font-size: 210% !important;
}
.bigger-220 {
  font-size: 220% !important;
}
.bigger-230 {
  font-size: 230% !important;
}
.bigger-240 {
  font-size: 240% !important;
}
.bigger-250 {
  font-size: 250% !important;
}
.bigger-260 {
  font-size: 260% !important;
}
.bigger-270 {
  font-size: 270% !important;
}
.bigger-280 {
  font-size: 280% !important;
}
.bigger-290 {
  font-size: 290% !important;
}
.bigger-300 {
  font-size: 300% !important;
}

/** Customizacoes UC **/

.required_field {
}

.required_field::after { 
    content: " *";
}

.form-group {
    margin-bottom: 7px;
}

.form-control {
	
}

.panel-heading
{
	padding-bottom: 5px;
}

.panel
{
	margin-bottom: 10px;
}


TABLE.dataTable TR:hover .form-control,
TABLE.dataTable TR:hover .fake-form-control
{
	background-color: #EEEEEE;
}

/* SELECT2 */

DIV.uc-select2-wrapper
{
/*	border:solid 1px red;    */
	margin-right: 10px;
}

/** fim **/

/**
  * Existem alteracoes no resto do arquivo. Fazer diff com style.css_original
  */

.btn:active,
.btn.active {
    box-shadow: none;
}
.btn:not(.uib-datepicker-popup *) {
    padding: 8px 14px;
    font-size: 10.5pt;
}

.btn {
    color: inherit;
    background-color: #1a4663 !important;
    border: none !important;
    color: #f0f8fc !important;
}

.btn:hover,
.btn:active:hover,
.btn.active:hover,
.open > .dropdown-toggle.btn:hover,
.btn:active:focus,
.btn.active:focus,
    .open > .dropdown-toggle.btn:focus,
.btn:active.focus,
.btn.active.focus {
    background-color: #00263e !important;
}

.buttons-margin .btn {
    margin-bottom: 5px;
}

.btn-default {
    border-color: #585d6e;
}

.btn.btn-w-sm {
    min-width: 80px;
}

.btn.btn-w-md {
    min-width: 120px;
}

.btn.btn-w-lg {
    min-width: 160px;
}

.btn.btn-rounded {
    border-radius: 50px;
}

.btn.btn-squared {
    border-radius: 0;
}

.btn-accent {
    color: #3e3e3e;
    background-color: transparent;
    border-color: #f6a821;
}

.btn-accent:focus,
.btn-accent.focus {
    color: #ffffff;
    background-color: rgba(246, 168, 33, 0.1);
    border-color: #f6a821;
    outline: 0;
    box-shadow: none;
}

.btn-accent:hover {
    color: #ffffff;
    background-color: rgba(246, 168, 33, 0.1);
    border-color: #f6a821;
}

.btn-accent:active,
.btn-accent.active,
.open > .dropdown-toggle.btn-accent {
    color: #ffffff;
    background-color: rgba(246, 168, 33, 0.1);
    border-color: #f6a821;
}

.btn-accent:active:hover,
.btn-accent.active:hover,
.open > .dropdown-toggle.btn-accent:hover,
.btn-accent:active:focus,
.btn-accent.active:focus,
.open > .dropdown-toggle.btn-accent:focus,
.btn-accent:active.focus,
.btn-accent.active.focus,
.open > .dropdown-toggle.btn-accent.focus {
    color: #ffffff;
    background-color: rgba(246, 168, 33, 0.1);
    border-color: #f8bb52;
    outline: 0;
    box-shadow: none;
}

.btn-accent:active,
.btn-accent.active,
.open > .dropdown-toggle.btn-accent {
    background-image: none;
}

.btn-accent.disabled:hover,
.btn-accent[disabled]:hover,
fieldset[disabled] .btn-accent:hover,
.btn-accent.disabled:focus,
.btn-accent[disabled]:focus,
fieldset[disabled] .btn-accent:focus,
.btn-accent.disabled.focus,
.btn-accent[disabled].focus,
fieldset[disabled] .btn-accent.focus {
    background-color: rgba(246, 168, 33, 0.1);
    border-color: #f6a821;
}

.btn-accent .badge {
    color: transparent;
    background-color: #3e3e3e;
}

.btn-default {
    color: #3e3e3e;
    background-color: transparent;
    border-color: #616779;
}

.btn-default:focus,
.btn-default.focus {
    color: #ffffff;
    background-color: rgba(97, 103, 121, 0.1);
    border-color: #616779;
    outline: 0;
    box-shadow: none;
}

.btn-default:hover {
    color: #ffffff;
    background-color: rgba(97, 103, 121, 0.1);
    border-color: #616779;
}

.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    color: #ffffff;
    background-color: rgba(97, 103, 121, 0.1);
    border-color: #616779;
}

.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
    color: #ffffff;
    background-color: rgba(97, 103, 121, 0.1);
    border-color: #798094;
    outline: 0;
    box-shadow: none;
}

.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    background-image: none;
}

.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus {
    background-color: rgba(97, 103, 121, 0.1);
    border-color: #616779;
}

.btn-default .badge {
    color: transparent;
    background-color: #3e3e3e;
}

.btn-primary {
    color: #3e3e3e;
    background-color: transparent;
    border-color: #0F83C9;
}

.btn-primary:focus,
.btn-primary.focus {
    color: #ffffff;
    background-color: rgba(15, 131, 201, 0.1);
    border-color: #0F83C9;
    outline: 0;
    box-shadow: none;
}

.btn-primary:hover {
    color: #ffffff;
    background-color: rgba(15, 131, 201, 0.1);
    border-color: #0F83C9;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    color: #ffffff;
    background-color: rgba(15, 131, 201, 0.1);
    border-color: #0F83C9;
}

.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
    color: #ffffff;
    background-color: rgba(15, 131, 201, 0.1);
    border-color: #1d9fee;
    outline: 0;
    box-shadow: none;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus {
    background-color: rgba(15, 131, 201, 0.1);
    border-color: #0F83C9;
}

.btn-primary .badge {
    color: transparent;
    background-color: #3e3e3e;
}

.btn-success {
    color: #3e3e3e;
    background-color: transparent;
    border-color: #1bbf89;
}

.btn-success:focus,
.btn-success.focus {
    color: #ffffff;
    background-color: rgba(27, 191, 137, 0.1);
    border-color: #1bbf89;
    outline: 0;
    box-shadow: none;
}

.btn-success:hover {
    color: #ffffff;
    background-color: rgba(27, 191, 137, 0.1);
    border-color: #1bbf89;
}

.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
    color: #ffffff;
    background-color: rgba(27, 191, 137, 0.1);
    border-color: #1bbf89;
}

.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
    color: #ffffff;
    background-color: rgba(27, 191, 137, 0.1);
    border-color: #2ce1a5;
    outline: 0;
    box-shadow: none;
}

.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
    background-image: none;
}

.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus {
    background-color: rgba(27, 191, 137, 0.1);
    border-color: #1bbf89;
}

.btn-success .badge {
    color: transparent;
    background-color: #3e3e3e;
}

.btn-info {
    color: #3e3e3e;
    background-color: transparent;
    border-color: #56C0E0;
}

.btn-info:focus,
.btn-info.focus {
    color: #ffffff;
    background-color: rgba(86, 192, 224, 0.1);
    border-color: #56C0E0;
    outline: 0;
    box-shadow: none;
}

.btn-info:hover {
    color: #ffffff;
    background-color: rgba(86, 192, 224, 0.1);
    border-color: #56C0E0;
}

.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
    color: #ffffff;
    background-color: rgba(86, 192, 224, 0.1);
    border-color: #56C0E0;
}

.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
    color: #ffffff;
    background-color: rgba(86, 192, 224, 0.1);
    border-color: #81d0e8;
    outline: 0;
    box-shadow: none;
}

.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
    background-image: none;
}

.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus {
    background-color: rgba(86, 192, 224, 0.1);
    border-color: #56C0E0;
}

.btn-info .badge {
    color: transparent;
    background-color: #3e3e3e;
}

.btn-warning {
    color: #3e3e3e;
    background-color: transparent;
    border-color: #f7af3e;
}

.btn-warning:focus,
.btn-warning.focus {
    color: #ffffff;
    background-color: rgba(247, 175, 62, 0.1);
    border-color: #f7af3e;
    outline: 0;
    box-shadow: none;
}

.btn-warning:hover {
    color: #ffffff;
    background-color: rgba(247, 175, 62, 0.1);
    border-color: #f7af3e;
}

.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
    color: #ffffff;
    background-color: rgba(247, 175, 62, 0.1);
    border-color: #f7af3e;
}

.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
    color: #ffffff;
    background-color: rgba(247, 175, 62, 0.1);
    border-color: #f9c36f;
    outline: 0;
    box-shadow: none;
}

.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
    background-image: none;
}

.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus {
    background-color: rgba(247, 175, 62, 0.1);
    border-color: #f7af3e;
}

.btn-warning .badge {
    color: transparent;
    background-color: #3e3e3e;
}

.btn-danger {
    color: white !important;
    background-color: #DB524B !important;
    border-color: #DB524B !important;
}

.btn-danger:focus,
.btn-danger.focus,
.btn-danger:hover,
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
    background-color: #db251c !important;
    outline: 0;
    box-shadow: none;
}

.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
    background-color: #db251c !important;
}

.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
    background-image: none;
}

.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus {
    background-color: rgba(219, 82, 75, 0.1);
    border-color: #DB524B;
}

.btn-danger .badge {
    color: transparent;
    background-color: #3e3e3e;
}

.btn-default:hover,
.btn-default:active,
.btn-default:active:focus,
.btn-default:focus,
.btn-default.active {
    background-color: #00263e26;
}

.btn-link:hover,
.btn-link:active,
.btn-link:active:focus,
.btn-link:focus,
.btn-link.active {
    color: #f6a821;
    text-decoration: none;
}
/*
.content > div {
    opacity: 0;
}
*/

.pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    z-index: 2000;
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 5px;
    width: 200px;
    background: #fff;
    overflow: hidden;
}

.pace .pace-progress {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    max-width: 200px;
    z-index: 2000;
    display: block;
    position: absolute;
    top: 0;
    right: 100%;
    height: 100%;
    width: 100%;
    background: #f6a821;
}

.pace.pace-inactive {
    display: none;
}

/*
.pace-done .content > div {
    opacity: 1;
}*/

.text-muted {
    color: #868e96;
}

.text-accent {
    color: #f6a821;
}

.text-primary {
    color: #0F83C9;
}

.text-success {
    color: #1bbf89;
}

.text-info {
    color: #56C0E0;
}

.text-warning {
    color: #f7af3e;
}

.text-danger {
    color: #DB524B;
}

/* MARGINS & PADDINGS */
.p-xxs {
    padding: 5px !important;
}

.p-xs {
    padding: 10px !important;
}

.p-sm {
    padding: 15px !important;
}

.p-m {
    padding: 20px !important;
}

.p-md {
    padding: 25px !important;
}

.p-lg {
    padding: 30px !important;
}

.p-xl {
    padding: 40px !important;
}

.m-xxs {
    margin: 2px 4px;
}

.m-xs {
    margin: 5px;
}

.m-sm {
    margin: 10px;
}

.m {
    margin: 15px;
}

.m-md {
    margin: 20px;
}

.m-lg {
    margin: 30px;
}

.m-xl {
    margin: 50px;
}

.m-n {
    margin: 0 !important;
}

.m-l-none {
    margin-left: 0;
}

.m-l-xs {
    margin-left: 5px;
}

.m-l-sm {
    margin-left: 10px;
}

.m-l {
    margin-left: 15px;
}

.m-l-md {
    margin-left: 20px;
}

.m-l-lg {
    margin-left: 30px;
}

.m-l-xl {
    margin-left: 40px;
}

.m-l-n-xxs {
    margin-left: -1px;
}

.m-l-n-xs {
    margin-left: -5px;
}

.m-l-n-sm {
    margin-left: -10px;
}

.m-l-n {
    margin-left: -15px;
}

.m-l-n-md {
    margin-left: -20px;
}

.m-l-n-lg {
    margin-left: -30px;
}

.m-l-n-xl {
    margin-left: -40px;
}

.m-t-none {
    margin-top: 0;
}

.m-t-xxs {
    margin-top: 1px;
}

.m-t-xs {
    margin-top: 5px;
}

.m-t-sm {
    margin-top: 10px;
}

.m-t {
    margin-top: 15px;
}

.m-t-md {
    margin-top: 20px;
}

.m-t-lg {
    margin-top: 30px;
}

.m-t-xl {
    margin-top: 40px;
}

.m-t-xxl {
    margin-top: 50px;
}

.m-t-xxxl {
    margin-top: 60px;
}

.m-t-n-xxs {
    margin-top: -1px;
}

.m-t-n-xs {
    margin-top: -5px;
}

.m-t-n-sm {
    margin-top: -10px;
}

.m-t-n {
    margin-top: -15px;
}

.m-t-n-md {
    margin-top: -20px;
}

.m-t-n-lg {
    margin-top: -30px;
}

.m-t-n-xl {
    margin-top: -40px;
}

.m-r-none {
    margin-right: 0;
}

.m-r-xxs {
    margin-right: 1px;
}

.m-r-xs {
    margin-right: 5px;
}

.m-r-sm {
    margin-right: 10px;
}

.m-r {
    margin-right: 15px;
}

.m-r-md {
    margin-right: 20px;
}

.m-r-lg {
    margin-right: 30px;
}

.m-r-xl {
    margin-right: 40px;
}

.m-r-n-xxs {
    margin-right: -1px;
}

.m-r-n-xs {
    margin-right: -5px;
}

.m-r-n-sm {
    margin-right: -10px;
}

.m-r-n {
    margin-right: -15px;
}

.m-r-n-md {
    margin-right: -20px;
}

.m-r-n-lg {
    margin-right: -30px;
}

.m-r-n-xl {
    margin-right: -40px;
}

.m-b-none {
    margin-bottom: 0;
}

.m-b-xxs {
    margin-bottom: 1px;
}

.m-b-xs {
    margin-bottom: 5px;
}

.m-b-sm {
    margin-bottom: 10px;
}

.m-b {
    margin-bottom: 15px;
}

.m-b-md {
    margin-bottom: 20px;
}

.m-b-lg {
    margin-bottom: 30px;
}

.m-b-xl {
    margin-bottom: 40px;
}

.m-b-n-xxs {
    margin-bottom: -1px;
}

.m-b-n-xs {
    margin-bottom: -5px;
}

.m-b-n-sm {
    margin-bottom: -10px;
}

.m-b-n {
    margin-bottom: -15px;
}

.m-b-n-md {
    margin-bottom: -20px;
}

.m-b-n-lg {
    margin-bottom: -30px;
}

.m-b-n-xl {
    margin-bottom: -40px;
}

.space-15 {
    margin: 15px 0;
}

.space-20 {
    margin: 20px 0;
}

.space-25 {
    margin: 25px 0;
}

.space-30 {
    margin: 30px 0;
}

.label-accent {
    background-color: #f6a821;
}

body {
    background: #00263e;
    font-family: 'Montserrat', sans-serif;
    font-size: 10.5pt;
    color: #3e3e3e;
}

.content {
    margin-top: 60px;
    margin-left: 200px;
    position: relative;
    padding: 0px;
/*    transition: margin-left 0.3s ease-out;
    -webkit-transition: margin-left 0.3s ease-out;
    */
}

.content:before {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: -2;
    left: 0;
    right: 0;
    /*background-image: -webkit-radial-gradient(top, circle cover, #393b45 0%, #2d3038 70%);
    background-image: -moz-radial-gradient(center, circle cover, #393b45 0%, #2d3038 70%);
    background-image: -o-radial-gradient(center, circle cover, #393b45 0%, #2d3038 70%);
    background-image: radial-gradient(center, circle cover, #393b45 0%, #2d3038 70%);
    background-position: 50%, 50%;*/
}

.navigation {
    margin-top: 60px;
    background-color: white;
    width: 200px;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 95%;
    top: 0;
    transition: left 0.3s ease-out;
    -webkit-transition: left 0.3s ease-out;
}

.navigation nav {
    height: 100%;
}

.navigation:before {
    transition: left 0.3s ease-out;
    -webkit-transition: left 0.3s ease-out;
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: -1;
    left: 0;
    background-color: white;
    width: 200px;
}

body.nav-toggle .content {
    margin-left: 0;
}

body.nav-toggle .navigation {
    left: -200px;
}

body.nav-toggle .navigation:before {
    left: -200px;
}

@media (max-width: 767px) {
    .content {
        margin-left: 0;
        padding: 30px 0 10px 0;
    }

    .navigation {
        left: -200px;
        z-index: 2;
    }

    .navigation:before {
        left: -200px;
    }

    body.nav-toggle .content {
        margin-left: 0;
    }

    body.nav-toggle .navigation {
        left: 0;
        z-index: 2;
    }

    body.nav-toggle .navigation:before {
        left: 0;
    }
}

body.blank .content {
    margin: 0;
}

.container-center {
    max-width: 400px;
    margin: 10% auto 0;
    padding: 20px;
}

.container-center.sm {
    max-width: 200px;
}

.container-center.md {
    max-width: 600px;
}

.container-center.lg {
    max-width: 800px;
}

/* Various */
a {
    color: #f6a821;
    text-decoration: none;
}

a:hover,
a:focus {
    color: #f8bb52;
    outline: none;
}

hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid #00263e26;
}

code {
    padding: 2px 4px;
    font-size: 90%;
    color: #f6a821;
    background-color: #24262d;
    border-radius: 3px;
}

pre {
    font-size: 90%;
    color: #3e3e3e;
    background-color: #24262d;
    border-radius: 3px;
    border: none;
}

mark {
    background-color: #e9e599;
    border-radius: 2px;
    padding: .1em .4em;
}

label {
    color: #00263e;
    font-weight: 500;
}

sup {
    font-size: 12px;
    top: -0.8em;
}

.help-block {
    color: #6d757d;
}

.bs-example {
    background-color: white;
    padding: 10px;
    border-radius: 3px;
}

.show-grid [class^="col-"] {
    background-color: white !important;
    border: 1px solid #00263e26;
    padding-bottom: 10px;
    padding-top: 10px;
}

.slight {
    font-size: 11px;
    font-weight: 300;
}

.show-grid {
    margin: 10px 0 !important;
}

.row {
	padding-left: 5px;
    margin-left: -10px;
    margin-right: -10px;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    padding-left: 5px;
    padding-right: 10px;
}

/* Icons */
.font-example-box:hover {
    color: #ffffff;
}

.font-example-box {
    text-align: center;
    min-height: 90px;
    margin: 10px 0;
    font-size: 42px;
}

.font-example-box i {
    font-size: 22px;
}

.icon-example-name {
    display: block;
    font-size: 11px;
}

.back-link {
    float: left;
    width: 100%;
    margin: 10px;
}

/* UI Sortable */
.ui-sortable .panel-body {
    cursor: move;
}

.ui-sortable-placeholder {
    border: 1px dashed #535868 !important;
    visibility: visible !important;
}

.navbar {
    min-height: 60px;
}

.navbar-default .navbar-brand,
.navbar-default .navbar-brand:focus {
    width: 200px;
    background-color: #f6a821;
    height: 60px;
    padding: 20px 25px;
    font-weight: 400;
    letter-spacing: 10px;
    color: #FFFFFF;
	
	padding: 0px;
	text-align:center;
}

.navbar-default .navbar-brand {
    color: #FFFFFF;
}

.navbar-default .navbar-brand:hover {
    color: #FFFFFF;
    background-color: #f5a212;
}

.navbar-brand span {
    letter-spacing: normal;
    float: right;
    font-size: 12px;
    margin-left: -15px;
    color: #fce2b4;
}

.navbar-default {
    border-bottom: none;
    background-color: #FFFFFF;
}

.left-nav-toggle {
    float: left;
    padding: 9px 10px 8px 25px;
}

.left-nav-toggle:hover a {
    color: #f6a821;
}

.left-nav-toggle a {
    font-size: 30px;
    color: #d0d0d0;
}

.navbar-default .navbar-nav > li > a {
    color: #6a727a;
}

.left-nav-toggle a:hover,
.left-nav-toggle a:focus {
    text-decoration: none;
}

.navbar-form {
    margin-top: 11px;
    padding: 0;
}

.navbar-form input.form-control {
    border: none;
    box-shadow: none;
    color: #3e3e3e;
}

.navbar-form input.form-control::-webkit-input-placeholder {
    color: #6f7780;
}

.navbar-form input.form-control:-moz-placeholder {
    color: #6f7780;
}

.navbar-form input.form-control::-moz-placeholder {
    color: #6f7780;
}

.navbar-form input.form-control:-ms-input-placeholder {
    color: #6f7780;
}

.navbar-nav > li > a {
    padding: 20px 15px;
    text-transform: uppercase;
    font-weight: 500;
}

.navbar-nav > li:first-child > a {
    padding-left: 0;
}

.navbar-nav > li:last-child > a {
    padding: 10px 25px 10px 15px;
}

.navbar-nav.navbar-right a .label {
    margin: -5px 0 0 5px;
}

.profil-link img {
    height: 40px;
    margin-left: 10px;
}

.profil-link .profile-address {
    text-transform: none;
}

#mobile-menu {
    display: none;
}

@media (max-width: 767px) {
    #mobile-menu {
        display: block;
    }
}

#mobile-menu {
    float: right;
}

#mobile-menu .left-nav-toggle {
    padding-right: 25px;
}

.right-menu-root {
    display: flex;
    flex-direction: column;
}

.luna-nav.nav {
    padding-top: 15px;
    height: 100%;
}

.luna-nav.nav li > a,
.luna-nav.nav .nav-category {
    padding: 8px 15px 8px 25px;
    margin: 0 0;
}

.luna-nav.nav .nav-info {
    padding: 20px 25px 25px 25px;
    background-color: #e9e9e9;
    text-align: center;
    font-size: 10.3pt;
}

.luna-nav.nav .nav-info i {
    font-size: 34px;
}

.luna-nav.nav .nav-second li > a {
    padding-left: 40px;
}

.luna-nav.nav li.active .nav-second li > a {
    padding-left: 40px;
    border-left: none;
    color: #848c94;
}

.luna-nav.nav li.active .nav-second li.active a {
    padding-left: 34px;
    /*border-left: 6px solid #c37e08;*/
    color: #00263e;
}

.luna-nav.nav li.active .nav-second li.active a:hover {
    color: #00263e;
}

.luna-nav .nav-group-item {
    color: #00263e !important;
    font-size: 12pt;
    font-weight: bold;
}

.luna-nav.nav li.active .nav-second li a:hover {
    color: #c0c4c8;
}

.luna-nav.nav li > a {
    color: #848c94;
	/*color: #FFFFFF;*/
}

.luna-nav.nav li.active a {
    /*border-left: 6px solid #f6a821;*/
    padding-left: 19px;
    color: #c0c4c8;
}

.luna-nav.nav li a:hover,
.luna-nav.nav li a:focus {
    color: #c0c4c8;
    background: inherit;
}

.luna-nav.nav .nav-category {
    color: #FFFFFF;
    margin-top: 10px;
}

.luna-nav.nav .sub-nav-icon {
    float: right;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
}

.panel {
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: #3e3e3e;
    border-radius: 8px;
}

.panel .panel-footer {
    background-color: transparent;
    border: none;
}

.panel > .panel-heading {
    color: #00263e;
    font-size: 13pt;
    font-weight: bold;
    padding: 10px 10px 12px 18px;
}

.panel-filled > .panel-heading {
    border-bottom: 1px solid #d0d0d0;
}

.panel-tools {
    display: inline-block;
    float: right;
    margin-top: 0;
    padding: 0;
    position: relative;
}

.panel-tools a {
    margin-left: 2px;
    color: #3e3e3e;
    cursor: pointer;
}

.panel-tools a:hover {
    color: #00263e;
}

.panel.panel-c-blue {
    border-left: 3px solid #3e3e3e;
}

.panel.panel-c-accent {
    border-left: 3px solid #f6a821;
}

.panel.panel-c-primary {
    border-left: 3px solid #0F83C9;
}

.panel.panel-c-warning {
    border-left: 3px solid #f7af3e;
}

.panel.panel-c-info {
    border-left: 3px solid #56C0E0;
}

.panel.panel-c-success {
    border-left: 3px solid #1bbf89;
}

.panel.panel-c-danger {
    border-left: 3px solid #DB524B;
}

.panel.panel-b-accent {
    background-color: #f6a821;
    color: #ffffff;
}

.panel.panel-b-white {
    background-color: white;
    color: #61686f;
}

.panel.panel-b-white .panel-heading {
    color: #61686f;
}

.panel.panel-b-white .panel-footer {
    color: #61686f;
}

.panel.panel-filled {
    background-color: white;
}

.view-header {
    margin: 0px;
	margin-top: 25px;
    min-height: 50px;
    padding: 0 15px;
}

.view-header-small {
    margin: 0px;
	margin-top: 16px;
    min-height: 25px;
    padding: 0 8px;
}

.view-header .header-icon {
    font-size: 60px;
    color: white;
    width: 68px;
    float: left;
    margin-top: -8px;
    line-height: 0;
}

.view-header-small .header-icon {
    font-size: 30px;
    color: white;
    width: 34px;
    float: left;
    margin-top: -4px;
    line-height: 0;
}


.view-header .header-title {
    margin-left: 68px;
    color: white;
}

.view-header-small .header-title {
    margin-left: 34px;
}

.view-header .header-title h3 {
    margin-bottom: 2px;
}

.panel.collapsed .panel-body,
.panel.collapsed .panel-footer {
    display: none;
}

.panel.collapsed .fa.fa-chevron-up:before {
    content: "\f078";
}

.panel.collapsed .fa.fa-chevron-down:before {
    content: "\f077";
}

.pagination > li > a,
.pagination > li > span {
    color: #3e3e3e;
    background-color: transparent;
    border: 1px solid #585d6e;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    color: #ffffff;
    background-color: rgba(97, 103, 121, 0.1);
    border-color: #616779;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    color: #ffffff;
    background-color: rgba(97, 103, 121, 0.1);
    border-color: #616779;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
    color: #3e3e3e;
    background-color: transparent;
    border-color: #4a4f5d;
}

/* Progress bar */
.progress {
    border-radius: 2px;
    margin-bottom: 10px;
}

.progress-bar {
    background-color: white;
    text-align: right;
    padding-right: 10px;
    color: #3e3e3e;
}

.progress-small,
.progress-small .progress-bar {
    height: 10px;
}

.progress-bar-primary {
    border-right: 4px solid #0d74b1;
}

.progress-bar-success {
    border-right: 4px solid #18a979;
}

.progress-bar-info {
    border-right: 4px solid #0d74b1;
}

.progress-bar-warning {
    border-right: 4px solid #f6a526;
}

.progress-bar-danger {
    border-right: 4px solid #d73e36;
}

.full .progress-bar-success {
    background-color: #1bbf89;
    border-right: 4px solid #18a979;
}

.full .progress-bar-info {
    background-color: #0F83C9;
    border-right: 4px solid #0d74b1;
}

.full .progress-bar-warning {
    background-color: #f7af3e;
    border-right: 4px solid #f6a526;
}

.full .progress-bar-danger {
    background-color: #DB524B;
    border-right: 4px solid #d73e36;
}

.full .progress-bar-primary {
    background-color: #0F83C9;
    border-right: 4px solid #0d74b1;
}

.full .progress-bar {
    color: #ffffff;
}

.modal-content {
    background-color: white;
}
.modal-content .view-header,
.modal-content .view-header .header-icon,
.modal-content .view-header .header-title,
.modal-content .view-header .header-title *,
.modal-content .view-header-small,
.modal-content .view-header-small .header-icon,
.modal-content .view-header-small .header-title,
.modal-content .view-header-small .header-title * {
    color: #00263e !important;
}

.modal-content .tabs-container {
    background: #00263e;
    padding: 13px;
    border-radius: 12px;
}

.modal-content .tabs-container .panel.panel-filled {
    border-top-left-radius: 8px;
}

.modal-content .tabs-container .nav-tabs > li a {
    border-radius: 5px;
    margin: 2px;
}

.modal-content .modal-title {
    font-size: 22px;
    color: #00263e;
}

.modal-content .modal-header {
    border-color: #00263e;
    background-color: #00263e;
    padding: 40px 20px;
}

.modal-content .modal-header .modal-title {
    color: white;
}

.modal-content .modal-body {
    padding: 40px;
}

.modal-content .modal-footer {
    border: none;
    background-color: white;
    padding: 20px;
}

.modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: .5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #FFFFFF;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    margin-top: 10px;
}

.c-accent {
    color: #f6a821;
}

.c-blue {
    color: #00263e;
}

.c-text {
    color: #3e3e3e;
}

.font-light {
    font-weight: 300;
}

.font-normal {
    font-weight: 400;
}

.font-bold {
    font-weight: 600;
}

.font-extra-bold {
    font-weight: 700;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
    color: #a4aab0;
}

.label-default {
    background-color: #44464f;
}

.label-primary {
    background-color: #0F83C9;
}

.label-success {
    background-color: #1bbf89;
}

.label-info {
    background-color: #56C0E0;
}

.label-warning {
    background-color: #f7af3e;
}

.label-danger {
    background-color: #DB524B;
}

.badge {
    background-color: transparent;
    border: 1px solid #44464f;
    font-weight: 300;
    color: #aaacb5;
}

.vertical-container {
    /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
    width: 98%;
    margin: 0 auto;
}

.vertical-container::after {
    /* clearfix */
    content: '';
    display: table;
    clear: both;
}

.v-timeline {
    position: relative;
    padding: 0;
    margin-top: 2em;
    margin-bottom: 2em;
}

.v-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: #00263e26;
}

.vertical-timeline-content .btn {
    float: right;
}

.vertical-timeline-block {
    position: relative;
    margin: 2em 0;
}

.vertical-timeline-block:after {
    content: "";
    display: table;
    clear: both;
}

.vertical-timeline-block:first-child {
    margin-top: 0;
}

.vertical-timeline-block:last-child {
    margin-bottom: 0;
}

.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 1px solid #00263e26;
    text-align: center;
    background: white;
    color: #ffffff;
}

.vertical-timeline-icon i {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -9px;
}

.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background-color: rgba(68, 70, 79, 0.5);
    border-radius: 0.25em;
    border: 1px solid #00263e26;
}

.vertical-timeline-content:after {
    content: "";
    display: table;
    clear: both;
}

.vertical-timeline-content h2 {
    font-weight: 400;
    margin-top: 4px;
}

.vertical-timeline-content p {
    margin: 1em 0 0 0;
    line-height: 1.6;
}

.vertical-timeline-content .vertical-date {
    font-weight: 500;
    text-align: right;
}

.vertical-date small {
    color: #ffffff;
    font-weight: 400;
}

.vertical-timeline-content:after,
.vertical-timeline-content:before {
    right: 100%;
    top: 20px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.vertical-timeline-content:after {
    border-color: transparent;
    border-right-color: #00263e26;
    border-width: 10px;
    margin-top: -10px;
}

.vertical-timeline-content:before {
    border-color: transparent;
    border-right-color: #00263e26;
    border-width: 11px;
    margin-top: -11px;
}

.vertical-timeline-content h2 {
    font-size: 16px;
}

/* Nestable list */
.dd {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 20px;
}

.dd-list {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.dd-list .dd-list {
    padding-left: 30px;
}

.dd-collapsed .dd-list {
    display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 20px;
    line-height: 20px;
}

.dd-handle {
    display: block;
    margin: 5px 0;
    padding: 10px 10px;
    color: #3e3e3e;
    text-decoration: none;
    border: 1px solid #00263e26;
    background-color: rgba(68, 70, 79, 0.5);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.dd-handle span {
    font-weight: bold;
}

.dd-handle:hover {
    background-color: rgba(68, 70, 79, 0.8);
    cursor: pointer;
}

.dd-item > button {
    display: block;
    position: relative;
    cursor: pointer;
    float: left;
    width: 25px;
    height: 20px;
    margin: 10px 0;
    padding: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    background: transparent;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    font-weight: bold;
}

.dd-item > button:before {
    content: '+';
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    text-indent: 0;
}

.dd-item > button[data-action="collapse"]:before {
    content: '-';
}

.dd-placeholder,
.dd-empty {
    margin: 5px 0;
    padding: 0;
    min-height: 30px;
    background: transparent;
    border: 1px dashed #535868;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.dd-empty {
    border: 1px dashed #00263e26;
    min-height: 100px;
    background-color: transparent;
    background-size: 60px 60px;
    background-position: 0 0, 30px 30px;
}

.dd-dragel {
    position: absolute;
    z-index: 9999;
    pointer-events: none;
}

.dd-dragel > .dd-item .dd-handle {
    margin-top: 0;
}

.dd-dragel .dd-handle {
    -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}

/**
* Nestable Extras
*/
.nestable-lists {
    display: block;
    clear: both;
    padding: 30px 0;
    width: 100%;
    border: 0;
    border-top: 2px solid #e4e5e7;
    border-bottom: 2px solid #e4e5e7;
}

#nestable-menu {
    padding: 0;
    margin: 10px 0 20px 0;
}

#nestable-output {
    width: 100%;
    font-size: 0.75em;
    line-height: 1.333333em;
    font-family: open sans, lucida grande, lucida sans unicode, helvetica, arial, sans-serif;
    padding: 5px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

#nestable-output {
    font-size: 10.5pt;
    padding: 25px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.nav-tabs {
    border: none;
}
.nav-tabs > li {
    margin-bottom: 0px;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    background-color: white !important;
    color: #00263e;
    border-width: 0px;
}

.tab-pane .panel-body {
    border-top: none;
    /*background-color: rgb(240 248 252);*/
    margin-bottom: 20px;
}

.nav-tabs > li a:hover {
    background: transparent;
    border-color: transparent;
}

.nav-tabs > li > a {
    color: #3e3e3e;
}

.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
    border-bottom: 0;
}

.tabs-left .panel-body {
    position: static;
}

.tabs-left > .nav-tabs,
.tabs-right > .nav-tabs {
    width: 20%;
}

.tabs-left .panel-body {
    width: 80%;
    margin-left: 20%;
}

.tabs-right .panel-body {
    width: 80%;
    margin-right: 20%;
}

.tab-content > .tab-pane,
.pill-content > .pill-pane {
    display: none;
}

.tab-content > .active,
.pill-content > .active {
    display: block;
}

.tabs-below > .nav-tabs > li {
    margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
    float: none;
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
    margin-right: 0;
    margin-bottom: 3px;
}

.tabs-left > .nav-tabs {
    float: left;
    margin-right: 19px;
}

.tabs-left > .nav-tabs > li > a {
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}

.tabs-right > .nav-tabs {
    float: right;
    margin-left: 19px;
}

.tabs-right > .nav-tabs > li > a {
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    border-top: 1px solid #00263e26;
}

.table > thead > tr > th {
    border-bottom: none;
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #e3f6ff;
}

.grid-result-message {
    color: #3e3e3e;
    font-size: 10.5pt;
    font-weight: normal;
}

.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
    background-color: #d1edff;
}

.table-hover > tbody > tr:hover {
    background-color: #d1edff;
}

.table-bordered,
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
    border-color: #00263e26;
}

.panel-filled .table > thead > tr > th,
.panel-filled .table > tbody > tr > th,
.panel-filled .table > tfoot > tr > th,
.panel-filled .table > thead > tr > td,
.panel-filled .table > tbody > tr > td,
.panel-filled .table > tfoot > tr > td {
    border-color: #00263e26;
}

.panel-filled .table-bordered,
.panel-filled .table-bordered > thead > tr > th,
.panel-filled .table-bordered > tbody > tr > th,
.panel-filled .table-bordered > tfoot > tr > th,
.panel-filled .table-bordered > thead > tr > td,
.panel-filled .table-bordered > tbody > tr > td,
.panel-filled .table-bordered > tfoot > tr > td {
    border-color: #00263e26;
}

.panel-filled .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #e3f6ff;
}

.panel-filled .table-hover > tbody > tr:hover {
    background-color: #d1edff;
}

th {
    color: #00263e;
}

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
    background-color: rgba(27, 191, 137, 0.2);
    border: none;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
    background-color: #18a979;
}

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
    background-color: rgba(86, 192, 224, 0.2);
    border: none;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
    background-color: #40b8dc;
}

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
    background-color: rgba(247, 175, 62, 0.2);
    border: none;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
    background-color: #f6a526;
}

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
    background-color: rgba(219, 82, 75, 0.2);
    border: none;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
    background-color: #d73e36;
}

div.dt-button-info {
    background-color: #21242a;
    border-radius: 6px;
    border: none;
}

div.dt-button-info h2 {
    background-color: transparent;
    border-color: #00263e26;
}

.table-responsive {
    border-color: #00263e26 !important;
}

.alert-success {
    color: #ffffff;
    border-color: #1bbf89;
    background-color: #1bbf89;
}

.alert-success .alert-link {
    color: #1bbf89;
}

.alert-warning {
    color: #ffffff;
    border-color: #f7af3e;
    background-color: #f7af3e;
}

.alert-warning .alert-link {
    color: #f7af3e;
}

.alert-info {
    color: #ffffff;
    border-color: #56C0E0;
    background-color: #56C0E0;
}

.alert-info .alert-link {
    color: #56C0E0;
}

.alert-danger {
    color: #ffffff;
    border-color: #DB524B;
    background-color: #DB524B;
}

.alert-danger .alert-link {
    color: #DB524B;
}

.toast-success {
    color: #ffffff;
    border-color: #1bbf89;
    background-color: #1bbf89;
}

.toast-warning {
    color: #ffffff;
    border-color: #f7af3e;
    background-color: #f7af3e;
}

.toast-info {
    color: #ffffff;
    border-color: #56C0E0;
    background-color: #56C0E0;
}

.toast-error {
    color: #ffffff;
    border-color: #DB524B;
    background-color: #DB524B;
}

#toast-container > div {
    opacity: 1;
    margin-top: 20px;
    border-radius: 4px;
    padding: 20px 20px 20px 50px;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

#toast-container > div:hover {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
}

#toast-container.toast-top-right > div {
    margin-top: 60px;
}

.form-control {
    color: #3e3e3e;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    background-color: #f4f4f4;
}

.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #f3f3f3;
    border: none;
    opacity: 1;
}

.navbar-form .form-control {
    background-color: #ffffff;
}

.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label {
    color: #159269;
}

.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label {
    color: #f59b0d;
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
    color: #cb3029;
}

.has-success .form-control {
    border-color: #159269;
    border-style: solid;
    border-width: 1px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.has-warning .form-control {
    border-color: #f59b0d;
    border-style: solid;
    border-width: 1px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.has-error .form-control {
    border-color: #cb3029;
    border-style: solid;
    border-width: 1px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.support-question a {
    color: #3e3e3e;
}

.support-question a:hover {
    text-decoration: none;
}

.support-question a:focus {
    text-decoration: none;
}

.support-question:hover {
    background-color: rgba(68, 70, 79, 0.7);
}

.support-question.active {
    border-left: 3px solid #f6a821;
}

.support-question.active .panel-body {
    padding-left: 13px;
}

.jqstooltip {
    padding: 5px !important;
    border: none !important;
    border-radius: 10px;
    background-color: #f6a821 !important;
    width: auto !important;
    height: auto !important;
}

/* Charts */
.flot-chart {
    display: block;
    height: 300px;
    position: relative;
    color: #545454;
}

.flot-chart-analytics {
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
    height: 150px;
    position: relative;
}

.flot-chart-content {
    width: 100%;
    height: 100%;
    color: #545454;
}

.flot-text {
    color: #3e3e3e !important;
}

.hoverinfo {
    background-color: #f6a821 !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5) !important;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5) !important;
    border: none !important;
    border-radius: 4px !important;
    color: #ffffff !important;
    padding: 6px 12px !important;
}


/****************************/
.bootbox .modal-header
{
	padding: 20px;
}
.bootbox .modal-body
{
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
}
.bootbox .bootbox-body
{
	line-height: 30px;
}

.bootbox .bootbox-close-button.close
{
	color: #FFFFFF;
	font-size:30px;
}

.ui-select-highlight
{
	font-weight: normal;
	text-decoration: underline;
	
}


.inner-addon
{ 
    position: relative; 
}

/* style icon */
.inner-addon .addon.fa,
.inner-addon .addon.glyphicon
{
  position: absolute;
  padding: 10px;
  pointer-events: none;
}

/* align icon */
.left-addon .addon.fa,
.left-addon .addon.glyphicon 
{
	left:  0px;
}
.right-addon .addon.fa,
.right-addon .addon.glyphicon 
{
	right: 0px;
}

/* add padding  */
.left-addon input
{
	padding-left:  30px;
}

.inner-addon .fa
{
	font-size:18px;
}
.date-group
{
	background-color: #eee;
    border-radius: 4px;
}

.date-group-readonly
{
	background-color: #eee;
}
.date-group-readonly div.addon-clickable
{
	cursor: default;
}

.date-group:hover input
{
}

.date-group:hover div.addon-clickable
{
	color: #F6A821 !important;
}

.select-modal-wrapper .input-group-addon
{
    background-color: #eeeeee;
    border: none;
}

.select-modal-wrapper .addon
{
	margin-right: 40px;
	z-index: 5;
}

.select-modal-wrapper .form-control
{
	border-radius: 4px !important;
	padding:0px;
	margin:0px;
	min-height:34px;
	height:auto;
}

.select-modal-wrapper.readOnly .form-control
{
	background-color: #f3f3f3;
    border: none;
}

.select-modal-wrapper ul
{
	padding: 0px;
}

.select-modal-wrapper ul li
{
	background-color:rgb(228, 228, 228);
	border:solid 1px rgb(170, 170, 170);
	border-radius:3px;
	box-sizing:border-box;
	color:rgb(51, 51, 51);
	cursor:default;
	display:list-item;
	float:left;
	font-family:Montserrat, sans-serif;
	font-size:14px;
	height:28px;
	line-height:20px;
	list-style-image:none;
	list-style-position:outside;
	list-style-type:none;
	margin-bottom:3px;
	margin-left:5px;
	margin-right:0px;
	margin-top:3px;
	padding-bottom:3px;
	padding-left:18px;
	padding-right:5px;
	padding-top:3px;
	position:relative;
	text-align:left;
	
    -webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
           box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
}
.select-modal-wrapper.readOnly  ul li
{
	padding-left:5px;
}
.select-modal-wrapper ul li a.deleteIcon
{
	background-attachment:scroll;
	background-clip:border-box;
	background-color:rgba(0, 0, 0, 0);
	background-image:url("select2/select2.png");
	background-origin:padding-box;
	background-position-x:100%;
	background-position-y:0%;
	background-repeat: no-repeat;
	background-size:auto;
	box-sizing:border-box;
	color:rgb(246, 168, 33);
	cursor:auto;
	font-family:Montserrat, sans-serif;
	font-size:1px;
	height:13px;
	left:3px;
	line-height:20px;
	position:absolute;
	right:3px;
	text-align:left;
	text-decoration:none;
	top:6px;
	user-select:none;
	width:12px;
}

.tabs-container .nav > li > a
{
	padding-bottom: 5px;
	padding-top: 10px;
}

.tabs-container .panel.panel-filled {
    border-top-left-radius: 0px;
}

BUTTON.agenda-walk-right,
BUTTON.agenda-walk-left
{
	padding-left: 6px;
	padding-right: 3px;
}

/*
 * Customiza��es do ag-grid para atender a identidade visual do template Luna 
 */

.ag-bl-full-height
{
	background-color: white;
	
}

.ag-luna
{
	line-height: 1.4;
	font-family: 'Montserrat', sans-serif;
	font-size: 10.5pt;
	color: #707070;
	
	background-color: white;
	padding-top: 5px;

	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

.ag-luna DIV
{
	outline-color: transparent !important;
}

.ag-luna  img
{
	vertical-align: middle;
	border: 0;
}

.ag-luna .ag-cell
{
	line-height: inherit;
}

.ag-luna  .ag-cell-not-inline-editing
{
	padding: 2px;
}

.ag-luna  .ag-cell-range-selected-1:not(.ag-cell-focus )
{
	background-color: rgba(120, 120, 120, 0.4);
}

.ag-luna  .ag-cell-range-selected-2:not(.ag-cell-focus )
{
	background-color: rgba(80, 80, 80, 0.4);
}

.ag-luna  .ag-cell-range-selected-3:not(.ag-cell-focus )
{
	background-color: rgba(40, 40, 40, 0.4);
}

.ag-luna  .ag-cell-range-selected-4:not(.ag-cell-focus )
{
	background-color: rgba(0, 0, 0, 0.4);
}

.ag-luna  .ag-column-moving .ag-cell {
	-webkit-transition: left 0.2s;
	-moz-transition: left 0.2s;
	-o-transition: left 0.2s;
	-ms-transition: left 0.2s;
	transition: left 0.2s;
}

.ag-luna  .ag-column-moving .ag-header-cell
{
	-webkit-transition: left 0.2s;
	-moz-transition: left 0.2s;
	-o-transition: left 0.2s;
	-ms-transition: left 0.2s;
	transition: left 0.2s;
}

.ag-luna  .ag-column-moving .ag-header-group-cell {
	-webkit-transition: left 0.2s;
	-moz-transition: left 0.2s;
	-o-transition: left 0.2s;
	-ms-transition: left 0.2s;
	transition: left 0.2s;
}

.ag-luna  .ag-cell-focus
{
/*	border: 1px solid #a9a9a9; */
}

.ag-luna  .ag-cell-no-focus {
	border: none;
}

.ag-luna  .ag-cell-first-right-pinned {
	border-left: 1px solid #00263e26;
}

.ag-luna  .ag-cell-last-left-pinned {
	border-right: 1px solid #00263e26;
}

.ag-luna  .ag-cell-highlight {
	border: 1px solid #006400;
}

.ag-luna  .ag-cell-highlight-animation {
	-webkit-transition: border 1s;
	-moz-transition: border 1s;
	-o-transition: border 1s;
	-ms-transition: border 1s;
	transition: border 1s;
}

.ag-luna  .ag-value-change-delta {
	padding-right: 2px;
}

.ag-luna  .ag-value-change-delta-up {
	color: #006400;
}

.ag-luna  .ag-value-change-delta-down {
	color: #8b0000;
}

.ag-luna  .ag-value-change-value {
	background-color: transparent;
	border-radius: 1px;
	padding-left: 1px;
	padding-right: 1px;
	-webkit-transition: background-color 1s;
	-moz-transition: background-color 1s;
	-o-transition: background-color 1s;
	-ms-transition: background-color 1s;
	transition: background-color 1s;
}

.ag-luna  .ag-value-change-value-highlight {
	background-color: #cec;
	-webkit-transition: background-color 0.1s;
	-moz-transition: background-color 0.1s;
	-o-transition: background-color 0.1s;
	-ms-transition: background-color 0.1s;
	transition: background-color 0.1s;
}

.ag-luna  .ag-rich-select {
	font-size: 10.5pt;
	border: 1px solid #00263e26;
	background-color: #fff;
}

.ag-luna  .ag-rich-select-value {
	padding: 2px;
}

.ag-luna  .ag-rich-select-list {
	border-top: 1px solid #d3d3d3;
}

.ag-luna  .ag-rich-select-row {
	padding: 2px;
}

.ag-luna  .ag-rich-select-row-selected {
	background-color: #bde2e5;
}

.ag-luna  .ag-large-text {
	border: 1px solid #00263e26;
}

.ag-luna  .ag-header {
	/*color: #FFFFFF;*/
	/*border-bottom: 1px solid #00263e26;*/
	font-weight: normal;
}

.ag-luna  .ag-header-icon
{
	color: #3e3e3e;
	stroke: none;
	fill: #3e3e3e;
}

.ag-luna  .ag-no-scrolls .ag-header-container {
	background: -webkit-linear-gradient(#fff, #d3d3d3);
	background: -moz-linear-gradient(#fff, #d3d3d3);
	background: -o-linear-gradient(#fff, #d3d3d3);
	background: -ms-linear-gradient(#fff, #d3d3d3);
	background: linear-gradient(#fff, #d3d3d3);
	border-bottom: 1px solid #00263e26;
}

.ag-luna  .ag-header-cell
{
	/*border-right: 1px solid #00263e26;*/
	padding: 5px;
}

.ag-luna  .ag-header-cell-moving .ag-header-cell-label {
	opacity: 0.5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	filter: alpha(opacity = 50);
}

.ag-luna  .ag-header-cell-moving {
	background-color: #bebebe;
}

.ag-luna  .ag-header-group-cell {
	border-right: 1px solid #00263e26;
}

.ag-luna  .ag-header-group-cell-with-group {
	border-bottom: 1px solid #00263e26;
}

.ag-luna  .ag-header-cell-label {
	padding: 4px 2px 4px 2px;
}

.ag-luna  .ag-header-cell-text
{
	padding-left: 2px;
}

.ag-luna  .ag-header-group-cell-label {
	padding: 4px;
	padding-left: 10px;
}

.ag-luna  .ag-header-group-text {
	margin-right: 2px;
}

.ag-luna  .ag-header-cell-menu-button {
	padding: 2px;
	margin-top: 4px;
	border: 1px solid transparent;
	border-radius: 3px;
	-webkit-box-sizing: content-box
		/* When using bootstrap, box-sizing was set to 'border-box' */;
	-moz-box-sizing: content-box
		/* When using bootstrap, box-sizing was set to 'border-box' */;
	box-sizing: content-box
		/* When using bootstrap, box-sizing was set to 'border-box' */;
	line-height: 0px
		/* normal line height, a space was appearing below the menu button */;
}

.ag-luna  .ag-pinned-right-header {
	border-left: 1px solid #00263e26;
}

.ag-luna  .ag-header-cell-menu-button:hover {
	border: 1px solid #00263e26;
}

.ag-luna  .ag-body
{
	background-color: transparent;
}

.ag-luna  .ag-row
{
	-webkit-transition: background-color 0.1s;
	-moz-transition: background-color 0.1s;
	-o-transition: background-color 0.1s;
	-ms-transition: background-color 0.1s;
	transition: background-color 0.1s;
	
	line-height: 30px;
}

.ag-luna  .ag-row-odd
{
	background-color: transparent;
}

.ag-luna  .ag-row-even
{
	background-color: #e3f6ff;
}

.ag-luna  .ag-row-selected {
	background-color: #b0e0e6;
}

.ag-luna  .ag-floating-top .ag-row {
	background-color: #f0f0f0;
}

.ag-luna  .ag-floating-bottom .ag-row {
	background-color: #f0f0f0;
}

.ag-luna  .ag-overlay-loading-wrapper
{
	background-color: rgba(63, 65, 73, 0.8);
}

.ag-luna  .ag-overlay-loading-center {
	background-color: #fff;
	border: 1px solid #00263e26;
	border-radius: 10px;
	padding: 10px;
	color: #000;
}

.ag-luna  .ag-overlay-no-rows-center {
	background-color: #fff;
	border: 1px solid #00263e26;
	border-radius: 10px;
	padding: 10px;
}

.ag-luna  .ag-group-cell-entire-row {
	background-color: #f6f6f6;
	padding: 2px;
}

.ag-luna  .ag-footer-cell-entire-row {
	background-color: #f6f6f6;
	padding: 2px;
}

.ag-luna  .ag-group-cell {
	font-style: italic;
}

.ag-luna  .ag-group-expanded {
	padding-right: 4px;
}

.ag-luna  .ag-group-contracted {
	padding-right: 4px;
}

.ag-luna  .ag-group-value {
	padding-right: 2px;
}

.ag-luna  .ag-group-checkbox {
	padding-right: 2px;
}

.ag-luna  .ag-footer-cell {
	font-style: italic;
}

.ag-luna  .ag-menu {
	border: 1px solid #00263e26;
	background-color: #f6f6f6;
	cursor: default;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 10.5pt;
}

.ag-luna  .ag-menu .ag-tab-header {
	background-color: #e6e6e6;
}

.ag-luna  .ag-menu .ag-tab {
	padding: 6px 8px 6px 8px;
	margin: 2px 2px 0px 2px;
	display: inline-block;
	border-right: 1px solid transparent;
	border-left: 1px solid transparent;
	border-top: 1px solid transparent;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
}

.ag-luna  .ag-menu .ag-tab-selected {
	background-color: #f6f6f6;
	border-right: 1px solid #d3d3d3;
	border-left: 1px solid #d3d3d3;
	border-top: 1px solid #d3d3d3;
}

.ag-luna  .ag-menu-separator {
	border-top: 1px solid #d3d3d3;
}

.ag-luna  .ag-menu-option-active {
	background-color: #bde2e5;
}

.ag-luna  .ag-menu-option-icon {
	padding: 2px 4px 2px 4px;
	vertical-align: middle;
}

.ag-luna  .ag-menu-option-text {
	padding: 2px 4px 2px 4px;
	vertical-align: middle;
}

.ag-luna  .ag-menu-option-shortcut {
	padding: 2px 2px 2px 20px;
	vertical-align: middle;
}

.ag-luna  .ag-menu-option-popup-pointer {
	padding: 2px 4px 2px 4px;
	vertical-align: middle;
}

.ag-luna  .ag-menu-option-disabled {
	opacity: 0.5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	filter: alpha(opacity = 50);
}

.ag-luna  .ag-menu-column-select-wrapper {
	margin: 2px;
}

.ag-luna  .ag-filter-checkbox {
	position: relative;
	top: 2px;
	left: 2px;
}

.ag-luna  .ag-filter-header-container {
	border-bottom: 1px solid #d3d3d3;
}

.ag-luna  .ag-filter-apply-panel {
	border-top: 1px solid #d3d3d3;
	padding: 2px;
}

.ag-luna  .ag-filter-value {
	margin-left: 4px;
}

.ag-luna  .ag-selection-checkbox {
	padding-right: 4px;
}

.ag-luna  .ag-paging-panel {
	padding: 4px;
}

.ag-luna  .ag-paging-button {
	margin-left: 4px;
	margin-right: 4px;
}

.ag-luna  .ag-paging-row-summary-panel {
	display: inline-block;
	width: 300px;
}

.ag-luna  .ag-tool-panel {
	background-color: #f6f6f6;
	border-right: 1px solid #00263e26;
	border-bottom: 1px solid #00263e26;
	border-top: 1px solid #00263e26;
	color: #222;
}

.ag-luna  .ag-status-bar {
	color: #222;
	background-color: #f6f6f6;
	font-size: 10.5pt;
	height: 22px;
	border-bottom: 1px solid #00263e26;
	border-left: 1px solid #00263e26;
	border-right: 1px solid #00263e26;
	padding: 2px;
}

.ag-luna  .ag-status-bar-aggregations {
	float: right;
}

.ag-luna  .ag-status-bar-item {
	padding-left: 10px;
}

.ag-luna  .ag-column-drop-cell {
	background: -webkit-linear-gradient(#fff, #d3d3d3);
	background: -moz-linear-gradient(#fff, #d3d3d3);
	background: -o-linear-gradient(#fff, #d3d3d3);
	background: -ms-linear-gradient(#fff, #d3d3d3);
	background: linear-gradient(#fff, #d3d3d3);
	color: #000;
	border: 1px solid #00263e26;
}

.ag-luna  .ag-column-drop-cell-ghost {
	opacity: 0.5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	filter: alpha(opacity = 50);
}

.ag-luna  .ag-column-drop-cell-text {
	padding-left: 2px;
	padding-right: 2px;
}

.ag-luna  .ag-column-drop-cell-button {
	border: 1px solid transparent;
	padding-left: 2px;
	padding-right: 2px;
	border-radius: 3px;
}

.ag-luna  .ag-column-drop-cell-button:hover {
	border: 1px solid #00263e26;
}

.ag-luna  .ag-column-drop-empty-message {
	padding-left: 2px;
	padding-right: 2px;
	color: #00263e26;
}

.ag-luna  .ag-column-drop-icon {
	padding-right: 4px;
}

.ag-luna  .ag-column-drop {
	background-color: #f6f6f6;
}

.ag-luna  .ag-column-drop-horizontal {
	padding: 4px 4px 4px 4px;
	border-top: 1px solid #00263e26;
	border-left: 1px solid #00263e26;
	border-right: 1px solid #00263e26;
}

.ag-luna  .ag-column-drop-horizontal .ag-column-drop-cell {
	padding: 2px;
}

.ag-luna  .ag-column-drop-vertical {
	padding: 4px 4px 10px 4px;
	border-bottom: 1px solid #00263e26;
}

.ag-luna  .ag-column-drop-vertical .ag-column-drop-cell {
	margin-top: 2px;
}

.ag-luna  .ag-column-drop-vertical .ag-column-drop-empty-message {
	text-align: center;
	padding: 5px;
}

.ag-luna  .ag-pivot-mode {
	border-bottom: 1px solid #00263e26;
	padding: 4px;
	background-color: #f6f6f6;
}

.ag-luna  .ag-tool-panel .ag-column-select-panel {
	border-bottom: 1px solid #00263e26;
}

.ag-luna  .ag-select-agg-func-popup {
	cursor: default;
	position: absolute;
	font-size: 10.5pt;
	background-color: #fff;
	border: 1px solid #00263e26;
}

.ag-luna  .ag-select-agg-func-item {
	padding-left: 2px;
	padding-right: 2px;
}

.ag-luna  .ag-select-agg-func-item:hover {
	background-color: #bde2e5;
}

/*********************************************/

.ag-luna .btn
{
	margin-top:-3px;
	padding-top:2px;
	padding-bottom:0px;
	line-height:22px;
}

.ag-luna .ag-row-hover
{
	background-color: #d1edff;
	cursor: pointer;
}
.ag-luna .ag-row-selected
{
	background-color: #d1edff;
	color: #00263e;
}

.ag-luna .ag-row-selected .fa
{
	color: #00263e;
}

.ag-luna .line-mark-selected
{
	color: #00263e;
}

.ag-luna .ag-row-selected .line-mark-selected
{
	color: #00263e;
}

.ag-luna.pointer-on-hover .ag-row-hover
{
	cursor: pointer;
}

/*****************************************/

TABLE.ag-luna
{
	width: 100%;
	
	font-family: 'Montserrat', sans-serif;
	font-size: 10.5pt;
	color: #3e3e3e;
	
	background-color: #f0f8fc;
	
	border: solid 1px #00263e26;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

TABLE.ag-luna.ag-table-row-hover TR:hover
{
	background-color: #d1edff; 
	color: #FFFFFF;
}

TABLE.ag-luna TR.zebra-line
{
	background-color: white;
}

TABLE.ag-luna TH
{
	border-right: 1px solid #00263e26;
	border-bottom: 1px solid #00263e26;
	
	font-weight: normal;
	height: 40px;
		
	padding-left: 2px;
	padding-right: 2px;
	padding-top: 6px;
	padding-bottom: 2px;
	text-align: center;
}

TABLE.ag-luna TD
{
	border-right: 1px solid #00263e26;
	border-bottom: 1px solid #00263e26;
		
	line-height: 20px;
		
	padding: 2px;
	padding-top: 4px;
}

TABLE.ag-luna TD.no-grid-result
{
	font-style: italic;
	text-align: center;
}


/* TEMPORÃ�RIO - Enquanto existir o BUG no ag-grid */
body {
	overflow-y: scroll;
	padding-right: 0px !important
}
::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: #606b70 !important;
	font-style: italic !important;
}

:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #606b70 !important;
	font-style: italic !important;
	opacity: 1;
}

::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #606b70 !important;
	font-style: italic !important;
	opacity: 1;
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #606b70 !important;
	font-style: italic !important;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
	.x-ng-cloak {
	display: none !important;
}

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
	background-color: #a00;
	box-shadow: 0 0 10px #888;
	left: -3.5em;
	moz-box-shadow: 0 0 10px #888;
	moz-transform: rotate(-45deg);
	ms-transform: rotate(-45deg);
	o-transform: rotate(-45deg);
	overflow: hidden;
	position: absolute;
	top: 40px;
	transform: rotate(-45deg);
	webkit-box-shadow: 0 0 10px #888;
	webkit-transform: rotate(-45deg);
	white-space: nowrap;
	width: 15em;
	z-index: 9999;
	pointer-events: none;
}

.ribbon a {
	border: 1px solid #faa;
	color: #fff;
	display: block;
	font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
	margin: 1px 0;
	padding: 10px 50px;
	text-align: center;
	text-decoration: none;
	text-shadow: 0 0 5px #444;
	pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
	font-size: 10px;
	color: #ccc
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
	margin: 0.2em 0;
	background: #ccc;
	color: #000;
	padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
	padding: 5px 15px;
}

.navbar-brand > img {
	border-right: 1px solid #d0d0d0;
}

.logo .logo-img {
	height: 45px;
	display: inline-block;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
	margin: 50px auto 0 auto;
	width: 300px;
	font-size: 18px;
	font-weight: 200;
	line-height: 30px;
	background-color: #eee;
	border-radius: 6px;
	padding: 60px;
}

.hero-unit h1 {
	font-size: 60px;
	line-height: 1;
	letter-spacing: -1px;
}

.hipster {
	display: inline-block;
	width: 347px;
	height: 497px;
	background: url("../images/hipster.png") no-repeat center top;
	background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and ( min--moz-device-pixel-ratio: 2),
only screen and ( -o-min-device-pixel-ratio: 2/1),
only screen and ( min-device-pixel-ratio: 2),
only screen and ( min-resolution: 192dpi),
only screen and ( min-resolution: 2dppx) {
	.hipster {
		background: url("../images/hipster2x.png") no-repeat center top;
		background-size: contain;
	}
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
	color: white;
	background-color: red;
}

.pad {
	padding: 10px;
}

.break {
	white-space: normal;
	word-break: break-all;
}

.voffset {
	margin-top: 2px;
}

.voffset1 {
	margin-top: 5px;
}

.voffset2 {
	margin-top: 10px;
}

.voffset3 {
	margin-top: 15px;
}

.voffset4 {
	margin-top: 30px;
}

.voffset5 {
	margin-top: 40px;
}

.voffset6 {
	margin-top: 60px;
}

.voffset7 {
	margin-top: 80px;
}

.voffset8 {
	margin-top: 100px;
}

.voffset9 {
	margin-top: 150px;
}

.readonly {
	background-color: #eee;
	opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
	cursor: pointer;
}

.hand {
	cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover,
#healthCheck .popover {
	top: inherit;
	display: block;
	font-size: 10px;
	max-width: 1024px;
}

#healthCheck .popover {
	margin-left: -50px;
}

.health-details {
	min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
	display: inline;
	list-style: none;
	margin: 0;
	margin-left: 15px;
	padding: 0;
	vertical-align: 2px;
}

.point:last {
	margin: 0 !important;
}

.point {
	background: #DDD;
	border-radius: 2px;
	display: inline-block;
	height: 5px;
	margin-right: 1px;
	width: 20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert {
	text-overflow: ellipsis;
}

.alert pre {
	background: none;
	border: none;
	font: inherit;
	color: inherit;
	padding: 0;
	margin: 0;
}

.alert .popover pre {
	font-size: 10px;
}

.alerts .toast {
	position: fixed;
	width: 100%;
}

.alerts .toast.left {
	left: 5px;
}

.alerts .toast.right {
	right: 5px;
}

.alerts .toast.top {
	top: 55px;
}

.alerts .toast.bottom {
	bottom: 55px;
}

@media screen and (min-width: 480px) {
	.alerts .toast {
		width: 50%;
	}
}

@media screen and (min-width: 480px) {
	.alerts .toast {
		width: 50%;
	}
}

/* ==========================================================================
entity tables helpers
========================================================================== */

/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
	padding-left: 0 !important;
}

.no-padding-right {
	padding-right: 0 !important;
}

.no-padding-top {
	padding-top: 0 !important;
}

.no-padding-bottom {
	padding-bottom: 0 !important;
}

.no-padding {
	padding: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
	width: 1% !important;
}

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
}

.jh-table>tbody>tr>td {
	/* Align text in td vertically (top aligned by Bootstrap) */
	vertical-align: middle;
}

.jh-table>thead>tr>th>.glyphicon-sort,
 .jh-table>thead>tr>th>.glyphicon-sort-by-attributes,
	.jh-table>thead>tr>th>.glyphicon-sort-by-attributes-alt {
	/* less visible sorting icons */
	opacity: 0.5;
}

.jh-table>thead>tr>th>.glyphicon-sort:hover,
 .jh-table>thead>tr>th>.glyphicon-sort-by-attributes:hover,
	.jh-table>thead>tr>th>.glyphicon-sort-by-attributes-alt:hover {
	/* full visible sorting icons and pointer when mouse is over them */
	opacity: 1;
	cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row.jh-entity-details>dd {
	margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
	.row.jh-entity-details>dt {
		margin-bottom: 15px;
	}
	.row.jh-entity-details>dd {
		border-bottom: 1px solid #eee;
		padding-left: 180px;
		margin-left: 0;
	}
}

@media screen and (max-width: 768px) {
	.hide-mobile {
		display: none;
	}
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
	cursor: pointer;
}

.datetime-picker-dropdown>li.date-picker-menu div>table .btn-default,
	.uib-datepicker-popup>li>div.uib-datepicker>table .btn-default {
	border: 0;
}

.uib-datepicker-popup>li>div.uib-datepicker>table .btn-default.btn-sm {
	background-color: #F3F3F3;
}

.uib-datepicker-popup>li>div.uib-datepicker>table .btn-default.btn-sm:hover	{
	color: #f7af3e;
}

.uib-datepicker-popup>li>div.uib-datepicker>table .btn-default.btn-sm.active,
.uib-datepicker-popup>li>div.uib-datepicker>table .btn-default.btn-sm.active .text-info {
	color: #FFFFFF;
	background-color: #00263e;
	border: 1px solid #00263e;
}

.uib-datepicker-popup>li>div.uib-datepicker>table .btn {
	background-color: #f0f8fc !important;
	color: #00263e !important;
}

.uib-datepicker-popup>li>div.uib-datepicker>table .btn:hover {
	background-color: #d9ebff !important;
}

.datetime-picker-dropdown>li.date-picker-menu div>table:focus,
	.uib-datepicker-popup>li>div.uib-datepicker>table:focus {
	outline: none;
}

/* jhipster-needle-css-add-main JHipster will add new css style */

/*****************************************************************/

/*****************************************************************/

/********************* angularjs-dropdown-multiselect *********************/
.multiselect-parent {
	width: 100%;
}

.multiselect-parent .divider {
	display: none;
}

.multiselect-parent .dropdown-header {
	padding: 0px;
	padding-bottom: 3px;
}

.multiselect-parent .dropdown-menu {
	overflow: auto;
	max-height: 600px;
	margin-bottom: 100px;
}

.multiselect-parent .searchField {
	background-color: rgb(238, 238, 238);
	margin: 0px !important;
}

.multiselect-parent #selectAll,
.multiselect-parent #deselectAll {
	padding-left: 10px;
	padding-right: 10px;
}

.multiselect-parent button {
	background-image: url("../images/caret.png");
	background-repeat: no-repeat;
	background-position: right center;
	overflow: hidden;
	padding-right: 20px;
	text-align: left;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.multiselect-parent button:disabled {
	background-image: none;
}

.multiselect-parent button .caret {
	display: none;
}

.multiselect-parent .dropdown-menu-form .option {
	padding-left: 30px;
	padding-right: 10px;
}

.multiselect-parent .dropdown-menu-form .active .option {
	background-color: #DE981E;
	font-weight: normal;
	padding-left: 5px;
	padding-right: 5px;
}

.multiselect-parent .dropdown-menu-form .glyphicon {
	margin-right: 5px;
}

/******************** /angularjs-dropdown-multiselect *********************/
.w100 {
	width: 100%;
}

.text-align-center {
	text-align: center;
}

.text-highlight {
	color: #f6a821;
	font-size: 10.5pt;
}

.form-validator-message {
	display: none;
}

.form-validator-form-error .form-validator-message {
	display: inline;
}

DIV.uc-grid-loading {}

DIV.uc-grid-loading DIV {
	background-color: transparent;
	background-image: url("../images/loading2.gif");
	background-position: center center;
	background-size: 80px 80px;
	height: 80px;
	margin: auto;
	width: 80px;
}

/********************************************/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: 'Montserrat', sans-serif;
}

DIV.button-row {
	padding-top: 10px;
}

.icon-22 {
	width: 22px !important;
	height: 22px !important;
}

.domain-page DIV.uc-alert {
	margin-left: 15px;
	margin-right: 15px;
}

.modal-domain-page .header-icon i
{
	font-size: 24px;
}

.color-orange {
	color: #F6A821;
}

.my-footer {
	font-size: 10px;
	line-height: 20px;
	text-align: center;
}

.navbar-fixed-bottom {
	min-height: 20px !important;
	color: #F6A821;
	background-color: #FFFFFF;
	font-size: 13px;
	position: fixed;
}

input[type=checkbox] {
	transform: scale(1.5);
}


.sgm-tab li a {
	background-color: #1a4663 !important;
	border: none;
	color: white;
}

.sgm-tab li a:hover {
	color: white;
	
}

.tab-body {
	padding-top: 5px;
}
.sgm-tab li a:hover {
	background-color: rgb(0 38 62 / 28%) !important;
}

.sgm-tab li a:disabled,
.sgm-tab li a[disabled] {
	background-color: transparent !important;
	color: rgba(148, 155, 162, 0.3) !important;
	cursor: not-allowed;
}

.sgm-tab li.active a {
	/*	background-color: #494b54 !important;*/
}

DIV.history-icon {
	padding-right: 10px;
}

DIV.history-icon I {
	cursor: pointer;
	font-size: 22px;
}

DIV.history-grid DIV.history-grid-scroll-area {
	max-height: 400px;
	min-height: 200px;
	overflow-y: auto;
	overflow-x: hidden;
}

DIV.history-grid TABLE {
	width: 870px;
}

DIV.history-grid TABLE UL {
	padding-left: 20px;
}

#photoFrame {
	margin-top: 10px;
	min-height: 300px;
	text-align: center;
}

#photoFrame img {
	height: 300px;
	text-align: center;
}

#photoFrame.loading {
	background-image: url("../images/loading.gif");
	background-size: 200px 200px;
	background-position: center center;
	background-repeat: no-repeat;
}

/*
.description-select.form-control
{
	min-height:34px;
	height:auto;
}

.description-select .ui-select-choices-row-inner
{
	border-bottom: solid 1px #EEEEEE;
}
*/
.sub-title-select {
	font-size: 12px;
	margin-top: 8px;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-default {
	cursor: default !important;
}

.modal-dialog-90-percent .modal-dialog {
	width: 90%;
	min-width: 900px;
}

.modal-dialog-80-percent .modal-dialog {
	width: 80%;
	min-width: 800px;
}

.modal-dialog-85-percent .modal-dialog {
	width: 85%;
	min-width: 850px;
}

.modal-dialog-80-percent .modal-dialog {
	width: 80%;
	min-width: 800px;
}

.modal-dialog-70-percent .modal-dialog {
	width: 70%;
	min-width: 700px;
}

TABLE.uc-table {
	width: 100%;
	border-collapse: collapse;
}

.search-modal-dialog DIV.selected-item {
	background-color: rgb(228, 228, 228);
	border: solid 1px rgb(170, 170, 170);
	border-radius: 3px;
	box-sizing: border-box;
	color: rgb(51, 51, 51);
	cursor: default;
	float: left;
	font-family: Montserrat, sans-serif;
	font-size: 10.5pt;
	height: 28px;
	line-height: 20px;
	margin-bottom: 3px;
	margin-left: 5px;
	margin-right: 0px;
	margin-top: 3px;
	padding-bottom: 3px;
	padding-left: 18px;
	padding-right: 5px;
	padding-top: 3px;
	position: relative;
	text-align: left;
	-webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
}

.search-modal-dialog DIV.selected-item a.deleteIcon {
	background-attachment: scroll;
	background-clip: border-box;
	background-color: rgba(0, 0, 0, 0);
	background-image: url("select2/select2.png");
	background-origin: padding-box;
	background-position-x: 100%;
	background-position-y: 0%;
	background-repeat: no-repeat;
	background-size: auto;
	box-sizing: border-box;
	color: rgb(246, 168, 33);
	cursor: auto;
	font-family: Montserrat, sans-serif;
	font-size: 1px;
	height: 13px;
	left: 3px;
	line-height: 20px;
	position: absolute;
	right: 3px;
	text-align: left;
	text-decoration: none;
	top: 6px;
	user-select: none;
	width: 12px;
}

/* .downloadDialogModal {
	background-color: #F7AF3E;
	font-size: 12px;
	position: absolute !important;
	top: 0px !important;
	z-index: 10000 !important;
	width: 150px !important;
	border-top: solid 3px #CE8D1C;
	-webkit-border-bottom-right-radius: 8px;
	-webkit-border-bottom-left-radius: 8px;
	-moz-border-radius-bottomright: 8px;
	-moz-border-radius-bottomleft: 8px;
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
} */

.downloadDialogModal {
    display: flex !important;
} 

#downloadDialog {
	width: 100%;
    height: 100%;
	position: fixed;
	top: 0;
	justify-content: center;
    align-items: center;
    z-index: 999;
    background: #0000007a;
}

#downloadDialog img {
	margin: 3px;
	margin-right: 8px;
	width: 30px;
}

#downloadDialog span {
	color: #FFFFFF;
	padding-left: 10px;
}

.close-button-header {
	background-color: transparent;
	border: none;
	color: #4A4B4F;
	cursor: pointer;
	font-size: 30px;
	margin-right: 10px;
	margin-top: -5px;
}

.close-button-header:hover {
	color: #8D8E91
}

.close-button-footer {
	padding: 10px;
	padding-right: 20px;
	text-align: right;
}

.uib-time input {
	background-color: #F3F3F3;
}

.time-pickup-wrapper .dropdown-menu {
	min-width: 200px;
}

.time-pickup-wrapper:hover div.addon-clickable {
	color: #F6A821 !important;
}

DIV.date-time-clear {
	background-color: #DDDDDD;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	color: #555;
	cursor: pointer;
	display: table-cell;
	font-size: 10.5pt;
	height: 34px;
	line-height: 14px;
	text-align: center;
	vertical-align: bottom;
	padding-bottom: 8px;
	padding-left: 12px;
	padding-right: 12px;
	width: 34px;
}

.form-group .button-panel-form-group {
	padding-top: 22px;
	text-align: center;
}

.panel-html {
	padding-bottom: 4px;
	padding-top: 8px;
	padding-left: 6px;
	padding-right: 6px;
}

DIV.state-button {
	border: solid 1px #3e3e3e;
	border-radius: 4px;
	cursor: pointer;
	display: inline-block;
	margin: 2px;
	padding-left: 12px;
	padding-right: 12px;
	padding-top: 6px;
	padding-bottom: 6px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

DIV.state-button:hover {
	border: solid 1px #A4AbB2;
	color: #00263e;
	background-color: rgb(240 248 252);
}

DIV.state-button.active {
	color: #1bbf89;
	border-color: #1bbf89;
	margin-bottom: 4px;
}

DIV.state-button.inactive {
	border-top-width: 3px;
}

DIV.datetime-form-field .vertical>DIV {
	padding-bottom: 4px;
}

.form-field-disable-wrapper .form-control {
	color: #555555;
}

.form-field-disable-wrapper .pe,
.form-field-disable-wrapper .fa,
	.form-field-disable-wrapper .glyphicon {
	display: none;
}

.supress-div-padding:not(.inner-addon .addon.glyphicon ) DIV,
	.supress-div-padding:not(.inner-addon .addon.fa ) DIV {
	padding: 0px !important;
}

.supress-div-margin DIV {
	margin: 0px !important;
}

DIV.photo-popover-content {
	text-align: center;
	height: 300px;
	width: 300px;
}

DIV.photo-popover-content .photo-popover-loading {
	margin-top: 50px;
	height: 200px;
	width: 200px;
}

DIV.photo-popover-content .photo-popover {
	border-radius: 10px;
	height: 300px;
	width: 300px;
}

DIV.photo-popover-content .photo-close {
	font-size: 25px;
	padding-right: 5px;
	position: absolute;
	width: 100%;
}

.photo-popover-wrapper {
	height: 306px;
	width: 306px;
	max-height: 306px;
	max-width: 306px;
}

.photo-popover-wrapper .popover-content {
	padding: 0px !important;
}

button:disabled,
button[disabled] {
	opacity: 0.4 !important;
}

.uib-daypicker TD,
.uib-daypicker TH {
	border: none !important;
}

.uib-daypicker TH
{
	color: #9399A0;
}

.uib-daypicker TD {
	padding: 2px;
}

TEXTAREA.medium-textarea {
	height: 150px;
}

TEXTAREA.small-textarea {
	height: 80px;
}

.display-none {
	display: none;
}
.display-inline-block {
	display: inline-block;
}

.z-index-2100 {
	z-index: 2100;
}

.z-index-2200 {
	z-index: 2200;
}

@media ( max-width : 1250px) {
	.client-tabs-hidden {
		display: none;
	}
}

@media ( max-width : 1050px) {
	.attendance-tabs-hidden {
		display: none;
	}
}

.system-info-right-menu {
	font-size: 11px;
	margin-top: 10px;
	text-align: center;
}

.co-day-picker button {
	background-color: transparent;
	padding: 8px 12px;
	border-radius: 2px;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
}

.co-day-picker button:hover {
	background-color: rgba(0, 0, 0, 0.05);
}

.co-day-picker button.selected {
	background-color: #4CAF50;
	color: #fff;
	box-shadow: 0 0 0 1px rgb(68, 157, 72);
	text-shadow: 1px 1px rgb(68, 157, 72);
}

.co-day-picker button[disabled] {
	color: rgba(0, 0, 0, 0.26);
	background-color: rgba(0, 0, 0, 0.12);
}

.margin-bottom-day-week {
	margin-bottom: 10px;
}

.margin-bottom-solid {
	border-bottom: 1px solid #adadad;
	width: 362px;
	padding-left: 10px;
	height: 25px;
	vertical-align: middle;
	display: table-cell;
}

.margin-right-day-week {
	margin-right: 10px;
}

.margin-left-day-week {
	margin-left: 10px;
}

.margin-right-day-week {
	margin-top: 10px;
}


.border-day-week-schedule {
	border: 1px solid #adadad;
	min-height: 210px;
	padding-left: 0px !important;
	padding-right: 0px !important;
	margin-top: 1%;
}

.border-availability-obs {
	border: 1px solid #adadad;
	padding-left: 0px !important;
	padding-right: 0px !important;
	margin-top: 1%;
}

DIV.legend-div-wrapper
{
	background-color: #FFFFFF;
	min-width: 1020px;
	padding-bottom: 8px;
	padding-top: 10px;
}

DIV.legend-div-wrapper TABLE TD
{
	text-align: center;
	width: 16.6%;
}


.div-legend {
	border: 1px solid #adadad;
	min-height: auto;
	padding-left: 0px !important;
	padding-right: 0px !important;
	margin-top: 2%;
}

.border-block-schedule {
	border: 1px solid #adadad;
	min-height: 210px;
	padding-left: 0px !important;
	padding-right: 0px !important;
	margin-top: 1%;
	float: right;
	margin-bottom: 10px;
}

.border-reminder-schedule {
	border: 1px solid #adadad;
	min-height: 30px;
	padding-left: 0px !important;
	padding-right: 0px !important;
	margin-top: 1%;
	float: right;
	margin-bottom: 10px;
}

.day-week-title {
	width: 100%;
	line-height: 34px;
	height: 34px;
	display:block;
	margin-bottom: 0px;
}

.border-day-week {
	border: 1px solid #adadad;
	border-radius: 5px;
	min-height: 210px;
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.border-day-week>div {
	text-align: center;
	height: 100%;
}

.schedulesRegistered {
	border-bottom: 1px solid #adadad;
	width: 100%;
	padding-left: 10px !important;
	padding-right: 0px !important;
	color: #00263e;
	height: 30px;
	text-align: left;
	padding-top: 3px;
}

.calendar {
/*	margin-top: 2%;*/
}

.uc-calendar
{
	background-color: #FFFFFF;
	border-radius:4px;
	padding-top:10px;
}

.modal-dialog-schedule .modal-dialog
{
	margin-top: 10px;
	margin-bottom: 10px;
}

.modal-dialog-schedule .modal-dialog .panel-body
{
	height: calc(100vh - 100px);
	padding: 2px;
}

.margin-buttom-confirmation-schedule {
	margin-top: 2%;
	margin-left: 1%
}

.fc-event
{
	border: 1px solid #DDDDDD;
	background-color: #FFFFFF;
	color: #777777;
}

.fc-event:hover
{
	background-color: #FFFFFF;
	color: orange;
	border-color: orange;
}

.fc-block
{
	background-image: url("../images/block-bg.png");
}

.fc-day-grid-event .fc-time
{
    font-weight: normal !important;
}
.fc-bg
{
	background-color: #FDFDFD;
}
/*
.fc-schedulesConfirmed {
	background: #CE8D1C !important;
	border-color: #CE8D1C !important;
}

.fc-schedulesAvailable  {
	background-color: #8dfc8f;
	border:1px solid #8dfc8f;
}

.fc-scheduledAppointment {
	background-color: #3A87AD;
	border:1px solid #3A87AD;
}
*/
.fc-toReschedule
{
	background-color: orange;
	border:1px solid #F5A837;
}

.fc-content
{
	min-height: 18px;
	line-height: 18px;
}

.fc-agendaWeek-view .fc-content
{
	min-height: 20px;
	overflow-x: hidden;
	white-space: nowrap;

}

.fc-agendaDay-view .fc-time-grid-event,
.fc-agendaWeek-view .fc-time-grid-event
{
	min-height: 20px;
}

.fc-agendaWeek-view .fc-slats td
{
	height: 30px;
}

.fc-agendaWeek-view .fc-title,
.fc-agendaWeek-view .fc-time
{
	padding-top: 2px !important;
	font-size: 12px !important;
}

.fc-agendaWeek-view .fc-title
{
	padding-left: 0px;
}

.fc-agendaWeek-view .fc-time
{
	padding-right: 0px;
}

.fc-agendaDay-view .fc-title
{
	padding-left: 4px !important;
}


/* Para nÃ£o deixar encavalr no meio * /
.fc-agendaWeek-view .fc-event
{
	left: 0px !important;
	margin-right: 0px !important;
}
*/
.fc-agendaDay-view .fc-content
{
	font-size: 18px;
	line-height: 20px;
	min-height: 20px;
}

.fc-agenda-cicle
{
    border-radius: 50%;
	border-style: solid;
	border-width: 1px;
    display: inline-block;
	height: 12px;
    margin-top: 1px;
    margin-bottom: -2px;
    margin-right: 4px;
    width: 12px;
}
/*
.fc-agendaWeek-view .fc-agenda-cicle
{
	margin-top:4px;
	margin-bottom:-4px;
}
*/
/*.fc-agendaWeek-view .fc-agenda-cicle,*/
.fc-agendaDay-view .fc-agenda-cicle
{
	height: 14px;
	margin-top:2px;
	margin-bottom:-2px;
    margin-right: 6px;
    width: 14px;
}

.fc-agenda-obs
{
	cursor: default;
    display: inline-block;
    font-size: 16px;
    margin-right: 4px;
    margin-bottom: -4px;
}

/*
.fc-agendaWeek-view .fc-agenda-obs
{
     padding-top: 1px;
}*/

.fc-agendaWeek-view .fc-agenda-obs
{
	font-size: 10.5pt;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 4px;
}

.fc-agendaDay-view .fc-agenda-obs
{
	font-size: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 6px;
}


.fc-agenda-locking
{
	padding-left: 4px;
}

.fc-agenda-reminder
{
	padding-left: 4px;
}

.fc-time-grid-event.fc-short .fc-time:after {
	content: "" !important; /* Tira o tracinho */
}

.backgound-schedulesAvailable {
	background: #8dfc8f !important;
}

.backgound-schedulesBlock {
	background: #fc6464 !important;
}

.schedulesAvailable
{
	background-color: #EFF8FF !important;
	border-color: #DFD8DF !important;
	color: #199CE8 !important;
}

.fc-block-selected {
	background: #fc6464 !important;
	border-color: #fc6464 !important;
}

.fc-reminder-selected {
	background-color: yellow !important;
	color: #917600 !important;
}

.scheduleDialog {
	padding-left:10px;
	padding-right:10px;
	padding-bottom:10px;
/*	background-color: #fff */
}

/*
.messageScheduling {
	text-align: center;
	margin-top: 10%;
	margin-bottom: 10%
}
*/
.fc-day-header{
	color: #000;
}

.fc-center > h2{
	color: #000 !important;
}

.fc-header-title h2 {
	color: #000 !important;
}

.float-right {
	float: right;
}

.width-140
{
	width: 140px;
}

.div-plannedDate {
	float:left;
	text-align:center;
	width:190px;
	margin-right:15px;
	padding-right:15px;
	border-right:solid 1px #d0d0d0;
}

.button-plannedDate {
	width:100%;
}

TD.icon-circle
{
	padding-right: 5px;
}


TD.icon-circle > DIV {
    height: 25px;
    width: 25px;
	border-radius: 50%;
    font-family: 'Montserrat' !important;
}

.circle-daysUnavailable
{
	background-image: url("../images/block-bg.png");
	border:1px solid #8D8E91;
}

.circle-schedulesBlocks {
	background-color: #fc6464;
	border:1px solid #fc6464;
}

.legend-title {
	background-color: #21242A;
}

TABLE.legend-div
{
	width: 1px;
}


.legend-div-inner
{
    white-space: nowrap;
}

/* .schedulesRegistered :hover {
	background-color: red;
} */


/* Sobrescrever o size, caso se aplique */
.background-loading
{
	background-color: transparent;
	background-image: url("../images/loading.gif");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 150px 150px;
}

.record-header-container {
	min-height: 40px;
}

.record-html-area-wrapper
{
	border-top: solid 1px #00263e26;
	padding-top: 4px;
}
/*
.record-html-area *
{
	color: #3e3e3e !important;
}
*/

.record-html-area.records-text
{
	background-color: #FFFFFF;
    border-radius: 6px;
	padding: 6px;
}

.record-html-area .attachment-title
{

}

.record-html-area .attachment-icon
{
	background-color: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
    font-size: 32px;
    margin-left: 10px;
    margin-top: 15px;
	padding: 6px;
	text-align: center;
	width: 60px;
}

DIV.template-tree
{
	height: 380px;
	overflow: auto;

}

DIV.template-tree ul
{
	list-style-type: none;
	padding-left: 15px;
}

DIV.template-tree li > DIV *
{
	border: none !important;
	cursor: pointer;
	padding-top: 5px;
}

DIV.template-tree li > DIV:hover LABEL
{
	text-decoration: underline;
}

/**
 * Para tirar a borda azul do Chrome
 */
 a:focus,
 i:focus,
 div:focus,
 span:focus,
 select:focus,
 button:focus
 {
 	outline: 0px !important;
 }

 DIV.static-tooltip-box
 {
 	background-color: #FFFFFF;
 	top: 20px;
 	left: 20px;
 	z-index: 100000;
 	position: absolute;
 }

 DIV.static-tooltip-box DIV.tooltip-footer
 {
 	background-image: url("../images/tooltip-bottom.png");
 	background-repeat: no-repeat;
 	background-position: center center;
 	height: 20px;
 }

DIV.schedule-static-tooltip-box
{
 	background-color: white;
 	border-radius: 6px;
 	padding: 10px;
}

.tooltip-item-icon {
	color: #f6a821;
}

DIV.schedule-static-tooltip-box .static-tooltip-close-icon
{
	margin-top: -6px;
}

DIV.tooltip-item
{
 	overflow: hidden;
 	padding-bottom: 2px;
	white-space: nowrap;
}
DIV.tooltip-item DIV.tooltip-item-icon
{
	display:inline-block;
	text-align:center;
	width:16px;
}

.datetime-wrapper-rescheduled-date .date-group-readonly,
.datetime-wrapper-rescheduled-date input
{
	background-color: orange !important;
	color: #FFFFFF !important;
	border-radius: 4px;
}
.datetime-wrapper-rescheduled-date .fa
{
	color: #FFFFFF !important;
}

.uc-hover:hover
{
	text-decoration: underline;
}

.attendance-rescheduling-flag
{
	color: #f8bb52 !important;
	cursor: pointer;
	display: table-cell;
	margin-left: 10px;
	font-size: 20px;
}

.attendance-rescheduling-flag-separator
{
	display: table-cell;
	width: 10px;
}

DIV.sessions-details-grid-cell,
DIV.sessions-details-grid-cell > DIV
{
	line-height: 20px !important;
	padding: 2px !important;
}

DIV.planned-date-grid-cell,
DIV.planned-date-grid-cell > DIV
{
	line-height: 20px !important;
	padding: 2px !important;
}

.configImage1,
.configImage2 {
	margin-top: 10px;
	min-height: 100px;
	text-align: center;
}

.configImage1 img {
	height: 125px;
	text-align: center;
}
.configImage2 img {
	height: 100px;
	text-align: center;
}

.configImage1.loading,
.configImage2.loading {
	background-image: url("../images/loading.gif");
	background-size: 80px 80px;
	background-position: center center;
	background-repeat: no-repeat;
}

.grid-attendance-row-locking
{
	color: #db251c !important;
    font-weight: 600;
}

.grid-attendance-row-reminder
{
	color: #1a4663 !important;
	font-style: italic;
}

.grid-attendance-row-locking .icon-22,
.grid-attendance-row-locking .fa-user,
.grid-attendance-row-reminder .icon-22,
.grid-attendance-row-reminder .fa-user

{
	display: none;
}

.grid-row-accent
{
	background: #2b5874 !important;
	color: white !important;
}

DIV.no-user-avatar I
{
	font-size:50px;
}

.navbar-left {
	height: 60px;
	border-left:solid 1px #DDDDDD;
	margin-left:15px;
	margin-right:15px;
}
.client-logo {
	height: 100%;
	padding: 0px 20px;
	display: flex;
	align-items: center;
}
.client-logo-text {
	margin-left: 8px;
	color: #f15921;
}
.client-logo-title {
    font-size: 10pt;
	font-weight: bold;
	margin-bottom: -3px;
}
.client-logo-subtitle {
    font-size: 9pt;
}
.client-logo-image img {
	height: 29px;
}
DIV.date-preview
{
	background-color: rgb(221, 221, 221);
	border: solid 1px rgb(148, 155, 162);
	border-radius:4px;
	color: rgb(85, 85, 85);
	float: left;
	padding-bottom: 4px;
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 4px;
	text-align:center;
}


DIV.hint-attendance-confirmation-tooltip-box
{
 	background-image: url("../images/tooltip-right-white.png");
 	background-repeat: no-repeat;
 	background-position: right center;
 	display: none;
 	min-height: 275px;
	left:100px;
 	padding-right: 20px;
	position: absolute;
	top:100px;
 	width: 360px;
 	z-index: 100;

 	/*
 	display: block;
 	top: 100px;
 	left: 100px;
 	*/
}

DIV.hint-attendance-confirmation-tooltip-box DIV.hint-attendance-confirmation-tooltip
{
 	background-color: #FFFFFF;
 	border-radius:10px;
 	height: 100%;
 	padding: 10px;
}

DIV.hint-attendance-confirmation-tooltip-box DIV.hint-attendance-confirmation-tooltip DIV.hint-attendance-confirmation-detail > DIV
{
	border:solid 1px #EAEAEA;
	border-radius: 4px;
	display: inline-block;
	font-size: 12px;
	padding-left:2px;
	padding-right:2px;
	white-space: nowrap;

}

DIV.hint-attendance-confirmation-tooltip-box label
{
 	color: #3e3e3e;
}

DIV.hint-attendance-confirmation-tooltip-box textarea
{
	border: solid 1px #DDDDDD;
 	background-color: #FFFFFF;
 	height: 36px;
}


DIV.hint-attendance-confirmation-tooltip-box .hint-attendance-confirmation-tooltip-close-icon
{
	margin-top: 2px;
	margin-right: 6px;
}

DIV.hint-attendance-confirmation-tooltip-box .btn-success
{
	background-color:#21BF8B;
}

DIV.hint-attendance-confirmation-tooltip-box .btn-danger
{
	background-color:#DA514D;
}

DIV.hint-attendance-confirmation-tooltip-box .btn-accent
{
	background-color:#F6A821;;
}

DIV.hint-attendance-confirmation-tooltip-box .btn-default
{
	background-color:#616779;
}

DIV.hint-attendance-confirmation-tooltip-box .btn
{
	color:#00263e;
	width: 100%;
}
DIV.hint-attendance-confirmation-tooltip-box .btn:hover
{
	color:#00263e;
}

.hide-history .history-icon
{
	display: none;
}

.whatsapp-style
{
	background-color: #14C150;
	color: #FFFFFF;
}
n
/**
Css para a árvore de subcategorias de Fornecedor.
**/

.selected-tree-item{
    color: #f8bb52;
    font-weight: bold;
    font-family: Verdana, Helvetica, Arial, sans-serif;
    border: thin 1px yellow;
}

.selected-tree-item::before {
	content: "\f00c";
	font-family: FontAwesome;
}

/**
CSS para exibir o blockUI sobre modal
 */
.block-ui-container {
    z-index: 15000 !important;
}

.block-ui-message {
	background: white;
	color: #00263e;
}

.center-block {
	float: none;
}

.phone-number-container
{
	border:solid 1px #EAEAEA;
	border-radius: 4px;
	display: inline-block;
	font-size: 12px;
	padding-left:2px;
	padding-right:2px;
	white-space: nowrap;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 10px;
	margin-bottom: 4px;
}

.attendance-container .ag-root.ag-scrolls {
	min-width: 1360px;
}
.form-prontuary-model-item-title {
	margin-top: 20px; 
	border-top: 1px solid white; 
	width: 100%;
}

.form-prontuary-model-item-title * {
	color: #00263e;
}
.uc-pills
{
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 8px;
	white-space:nowrap;
	font-size:9.8pt;
}

.uc-pills.uc-pills-small {
	padding: 5px;
}

.form-group .uc-pills
{
	display: table-cell;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 12px;
	padding-right: 12px;
}

.uc-pills-red
{
	background-color: red;
	color: #FFFFFF;
}

.uc-pills-red-2
{
	background-color: #FC6464;
	color: #FFFFFF;
}

.uc-pills-yellow
{
	background-color: yellow;
	color: #917600;
}

.uc-pills-green-light
{
	background-color: #AAFFAA;
	color: #009900;
}

.uc-pills-green
{
	background-color: #68e037;
    color: white;
}

.uc-pills-blue
{
	background-color: #5aa5d7;
	color: #FFFFFF;
}

.uc-pills-gray
{
	background-color: #f0f0f0;
	color: #707070;
}

.uc-pills-gray-light
{
	background-color: #DDDDDD;
	color: #000000;
}

/**** fc *****/

.fc-event .uc-pills-gray
{
	border-color: #8A8A8A; 
}
.fc-event .uc-pills-green
{
	border-color: #00DD00;
}
.fc-event .uc-pills-green-light
{
	border-color: #88DD88;
}
.fc-event .uc-pills-red-2
{
	border-color: #FC6464;
}


/*
Version: 3.4.5 Timestamp: Mon Nov  4 08:22:42 PST 2013
*/
.select2-container {
    margin: 0;
    position: relative;
    display: inline-block;
    /* inline-block for ie7 */
    zoom: 1;
    *display: inline;
    vertical-align: middle;
}

.select2-container,
.select2-drop,
.select2-search,
.select2-search input {
  /*
    Force border-box so that % widths fit the parent
    container without overlap because of margin/padding.

    More Info : http://www.quirksmode.org/css/box.html
  */
  -webkit-box-sizing: border-box; /* webkit */
     -moz-box-sizing: border-box; /* firefox */
          box-sizing: border-box; /* css3 */
}

.select2-container .select2-choice {
    display: block;
    height: 34px;
/*    padding: 0 0 0 8px;*/
	padding-left: 12px;
	padding-right: 12px;
	padding-top: 2px;
	padding-bottom: 6px; 
    overflow: hidden;
    position: relative;

/*    border: 1px solid #aaa;*/
    white-space: nowrap;
    line-height: 34px;
/*    color: #444;*/
    text-decoration: none;

    border-radius: 4px;

    background-clip: padding-box;

    -webkit-touch-callout: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;

/*    background-color: #fff;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #eee), color-stop(0.5, #fff));
    background-image: -webkit-linear-gradient(center bottom, #eee 0%, #fff 50%);
    background-image: -moz-linear-gradient(center bottom, #eee 0%, #fff 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#ffffff', endColorstr = '#eeeeee', GradientType = 0);
    background-image: linear-gradient(top, #fff 0%, #eee 50%);*/
}

.select2-container.select2-drop-above .select2-choice {
    border-bottom-color: #aaa;

    border-radius: 0 0 4px 4px;

    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #eee), color-stop(0.9, #fff));
    background-image: -webkit-linear-gradient(center bottom, #eee 0%, #fff 90%);
    background-image: -moz-linear-gradient(center bottom, #eee 0%, #fff 90%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0);
    background-image: linear-gradient(top, #eee 0%, #fff 90%);
}

.select2-container.select2-allowclear .select2-choice .select2-chosen {
    margin-right: 42px;
}

.select2-container .select2-choice > .select2-chosen {
    margin-right: 34px;
    display: block;
    overflow: hidden;

    white-space: nowrap;

    text-overflow: ellipsis;
}

.select2-container .select2-choice abbr {
    display: none;
    width: 12px;
    height: 12px;
    position: absolute;
    right: 16px;
    top: 10px;

    font-size: 1px;
    text-decoration: none;

    border: 0;
    background: url('select2.png') right top no-repeat;
    cursor: pointer;
    outline: 0;
}

.select2-container.select2-allowclear .select2-choice abbr {
    display: inline-block;
}

.select2-container .select2-choice abbr:hover {
    background-position: right -11px;
    cursor: pointer;
}

.select2-drop-mask {
    border: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 9998;
    /* styles required for IE to work */
/*    background-color: #fff;
    filter: alpha(opacity=0);*/
}

.select2-drop {
    width: 100%;
    margin-top: -1px;
    position: absolute;
    z-index: 9999;
    top: 100%;

    background: #fff;
    color: #000;
    border: 1px solid #aaa;
    border-top: 0;

    border-radius: 0 0 4px 4px;

    -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
            box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
}

.select2-drop-auto-width {
    border-top: 1px solid #aaa;
    width: auto;
}

.select2-drop-auto-width .select2-search {
    padding-top: 4px;
}

.select2-drop.select2-drop-above {
    margin-top: 1px;
    border-top: 1px solid #aaa;
    border-bottom: 0;

    border-radius: 4px 4px 0 0;

    -webkit-box-shadow: 0 -4px 5px rgba(0, 0, 0, .15);
            box-shadow: 0 -4px 5px rgba(0, 0, 0, .15);
}

.select2-drop-active {
    /*border: 1px solid #5897fb;*/
    border-top: none;
}

.select2-drop.select2-drop-above.select2-drop-active {
    /*border-top: 1px solid #5897fb;*/
}

.select2-container .select2-choice .select2-arrow {
    display: inline-block;
    width: 18px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;

/*    border-left: 1px solid #aaa;*/
    border-radius: 0 4px 4px 0;
/*
    background-clip: padding-box;

    background: #ccc;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #ccc), color-stop(0.6, #eee));
    background-image: -webkit-linear-gradient(center bottom, #ccc 0%, #eee 60%);
    background-image: -moz-linear-gradient(center bottom, #ccc 0%, #eee 60%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#eeeeee', endColorstr = '#cccccc', GradientType = 0);
    background-image: linear-gradient(top, #ccc 0%, #eee 60%);
    */
}

.select2-container .select2-choice .select2-arrow b {
    display: block;
    width: 100%;
    height: 100%;
    background: url('select2.png') no-repeat 0 4px;
}

.select2-search {
    display: inline-block;
    width: 100%;
    min-height: 34px;
    margin: 0;
    padding-left: 4px;
    padding-right: 4px;

    position: relative;
    z-index: 10000;

    white-space: nowrap;
}

.select2-search input {
    width: 100%;
    height: auto !important;
    min-height: 34px;
    padding: 4px 20px 4px 5px;
    margin: 0;

    outline: 0;
    font-family: sans-serif;
    font-size: 1em;

    border: 1px solid #aaa;
    border-radius: 0;

    -webkit-box-shadow: none;
            box-shadow: none;

    background: #fff url('select2.png') no-repeat 100% -22px;
    background: url('select2.png') no-repeat 100% -22px, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
    background: url('select2.png') no-repeat 100% -22px, -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
    background: url('select2.png') no-repeat 100% -22px, -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
    background: url('select2.png') no-repeat 100% -22px, linear-gradient(top, #fff 85%, #eee 99%);
}

.select2-drop.select2-drop-above .select2-search input {
    margin-top: 4px;
}

.select2-search input.select2-active {
    background: #fff url('select2-spinner.gif') no-repeat 100%;
    background: url('select2-spinner.gif') no-repeat 100%, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
    background: url('select2-spinner.gif') no-repeat 100%, -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
    background: url('select2-spinner.gif') no-repeat 100%, -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
    background: url('select2-spinner.gif') no-repeat 100%, linear-gradient(top, #fff 85%, #eee 99%);
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
    /*border: 1px solid #5897fb;*/
    outline: none;

    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
            box-shadow: 0 0 5px rgba(0, 0, 0, .3);
}

.select2-dropdown-open .select2-choice {
    border-bottom-color: transparent;
    -webkit-box-shadow: 0 1px 0 #fff inset;
            box-shadow: 0 1px 0 #fff inset;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    background-color: #eee;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #fff), color-stop(0.5, #eee));
    background-image: -webkit-linear-gradient(center bottom, #fff 0%, #eee 50%);
    background-image: -moz-linear-gradient(center bottom, #fff 0%, #eee 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
    background-image: linear-gradient(top, #fff 0%, #eee 50%);
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
    /*border: 1px solid #5897fb;
    border-top-color: transparent;

    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(0.5, #eee));
    background-image: -webkit-linear-gradient(center top, #fff 0%, #eee 50%);
    background-image: -moz-linear-gradient(center top, #fff 0%, #eee 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
    background-image: linear-gradient(bottom, #fff 0%, #eee 50%);*/
}

.select2-dropdown-open .select2-choice .select2-arrow {
    background: transparent;
    border-left: none;
    filter: none;
}
.select2-dropdown-open .select2-choice .select2-arrow b {
    background-position: -18px 1px;
}

/* results */
.select2-results {
    max-height: 200px;
    padding: 0 0 0 4px;
    margin: 4px 4px 4px 0;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.select2-results ul.select2-result-sub {
    margin: 0;
    padding-left: 0;
}

.select2-results ul.select2-result-sub > li .select2-result-label { padding-left: 20px }
.select2-results ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 40px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 60px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 80px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 100px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 110px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 120px }

.select2-results li {
    list-style: none;
    display: list-item;
    background-image: none;
}

.select2-results li.select2-result-with-children > .select2-result-label {
    font-weight: bold;
}

.select2-results .select2-result-label {
    padding: 3px 7px 4px;
    margin: 0;
    cursor: pointer;

    min-height: 1em;

    -webkit-touch-callout: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
}

.select2-results .select2-highlighted {
    background: #E4E4E4;
}

.select2-results li em {
    background: red;
    font-style: normal;
}

.select2-results .select2-highlighted em {
    background: transparent;
}

.select2-results .select2-highlighted ul {
    background: #fff;
    color: #000;
}


.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
    background: #f4f4f4;
    display: list-item;
}

/*
disabled look for disabled choices in the results dropdown
*/
.select2-results .select2-disabled.select2-highlighted {
/*    color: #666;
    background: #f4f4f4;*/
    display: list-item;
    cursor: default;
}
.select2-results .select2-disabled {
/*  background: #f4f4f4; */
  display: list-item;
  cursor: default;
}

.select2-results .select2-selected {
    display: none;
}

.select2-more-results.select2-active {
    background: #f4f4f4 url('select2-spinner.gif') no-repeat 100%;
}

.select2-more-results {
    background: #f4f4f4;
    display: list-item;
}

/* disabled styles */

.select2-container.select2-container-disabled .select2-choice {
/*    background-color: #f4f4f4;*/
    background-image: none;
    /*border: 1px solid #ddd;*/
    cursor: default;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
/*    background-color: #f4f4f4; */
    background-image: none;
    border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
    display: none;
}


/* multiselect */

.select2-container-multi .select2-choices {
    height: auto !important;
    height: 1%;
    margin: 0;
    padding: 0;
    position: relative;

    border: none;
    cursor: text;
    overflow: hidden;

/*    background-color: #fff;
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, color-stop(1%, #eee), color-stop(15%, #fff));
    background-image: -webkit-linear-gradient(top, #eee 1%, #fff 15%);
    background-image: -moz-linear-gradient(top, #eee 1%, #fff 15%);
    background-image: linear-gradient(top, #eee 1%, #fff 15%);
    */
}

.select2-locked {
  padding: 3px 5px 3px 5px !important;
}

.select2-container-multi .select2-choices {
    min-height: 34px;
}

.select2-container-multi.select2-container-active .select2-choices {
    /*border: 1px solid #5897fb;*/
    outline: none;

    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
            box-shadow: 0 0 5px rgba(0, 0, 0, .3);
}
.select2-container-multi .select2-choices li {
    float: left;
    list-style: none;
}
.select2-container-multi .select2-choices .select2-search-field {
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
    padding: 5px;
    padding-top: 8px;
    padding-left: 12px;
    margin: 1px 0;

    font-size: 100%;
    color: #3e3e3e !important;
    
    outline: 0;
    border: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
            
    background: transparent;
}

.select2-container-multi .select2-choices .select2-search-field input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #3e3e3e;
}
.select2-container-multi .select2-choices .select2-search-field input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #3e3e3e;
   opacity:  1;
}
.select2-container-multi .select2-choices .select2-search-field input::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #3e3e3e;
   opacity:  1;
}
.select2-container-multi .select2-choices .select2-search-field input:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #3e3e3e;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
    background: #fff url('select2-spinner.gif') no-repeat 100% !important;
}

.select2-default {
    color: #999 !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
    padding: 3px 5px 3px 18px;
    margin: 3px 0 3px 5px;
    position: relative;

    line-height: 20px;
    color: #333;
    cursor: default;
    border: 1px solid #aaaaaa;

    border-radius: 3px;

    -webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);

    background-clip: padding-box;

    -webkit-touch-callout: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;

    background-color: #e4e4e4;
    /*
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#f4f4f4', GradientType=0);
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), color-stop(100%, #eee));
    background-image: -webkit-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
    background-image: -moz-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
    background-image: linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);*/
}
.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
    cursor: default;
}
.select2-container-multi .select2-choices .select2-search-choice-focus {
    background: #d4d4d4;
}

.select2-search-choice-close {
    display: block;
    width: 12px;
    height: 13px;
    position: absolute;
    right: 3px;
    top: 6px;

    font-size: 1px;
    outline: none;
    background: url('select2.png') right top no-repeat;
}

.select2-container-multi .select2-search-choice-close {
    left: 3px;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover {
  background-position: right -11px;
}
.select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
    background-position: right -11px;
}

/* disabled styles */
.select2-container-multi.select2-container-disabled .select2-choices {
    /*background-color: #f4f4f4;*/
    background-image: none;
    /*border: 1px solid #ddd;*/
    cursor: default;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
    padding: 3px 5px 3px 5px;
    /*border: 1px solid #ddd;*/
    background-image: none;
    /*background-color: #f4f4f4;*/
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {    display: none;
    background: none;
}
/* end multiselect */


.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
    text-decoration: underline;
}

.select2-offscreen, .select2-offscreen:focus {
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    outline: 0 !important;
    left: 0px !important;
    top: 0px !important;
}

.select2-display-none {
    display: none;
}

.select2-measure-scrollbar {
    position: absolute;
    top: -10000px;
    left: -10000px;
    width: 100px;
    height: 100px;
    overflow: scroll;
}
/* Retina-ize icons */

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)  {
  .select2-search input, .select2-search-choice-close, .select2-container .select2-choice abbr, .select2-container .select2-choice .select2-arrow b {
      background-image: url('select2x2.png') !important;
      background-repeat: no-repeat !important;
      background-size: 60px 40px !important;
  }
  .select2-search input {
      background-position: 100% -21px !important;
  }
}
/********************************/
.select2.form-control 
{
	padding: 0px;
}
.search-container
{
	padding-top: 3px;
}
.select2-chosen
{
	color: #3e3e3e !important;
}

.select2-container-disabled .select2-default .select2-chosen
{
	color: transparent !important;
}

.ui-select-choices-row:hover
{
	background-color: #F0F0F0;
}


.select2 .no-result
{
	color: #999999;
	font-style: italic;
}

.select2-container-multi
{
    border-radius: 4px;
}

.select2-container-multi.select2-multiple-open
{
	background-color: rgb(238, 238, 238)
}
.blink
{
	animation: blink-animation 1s steps(5, start) infinite;
	-webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation
{
	to {
		visibility: hidden;
	}
}
@-webkit-keyframes blink-animation
{
	to {
		visibility: hidden;
	}
}
body > .select2-container.open
{
  z-index: 9999; /* The z-index Select2 applies to the select2-drop */
}